/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export const SHELL_PROD_SCOPES = [
  'ab.manage',
  'account_cluster.read',
  'additional_info',
  'additional_info.job_function',
  'additional_info.projectedProductContext',
  'additional_info.roles',
  'AdobeID',
  'adobeio.appregistry.read',
  'adobeio_api',
  'audiencemanager_api',
  'creative_cloud',
  'mps',
  'openid',
  'org.read',
  'pps.read',
  'read_organizations',
  'read_pc',
  'read_pc.acp',
  'read_pc.dma_tartan',
  'session'
].join(',');

export const SHELL_STAGE_SCOPES = [
  'ab.manage',
  'account_cluster.read',
  'additional_info',
  'additional_info.job_function',
  'additional_info.projectedProductContext',
  'additional_info.roles',
  'AdobeID',
  'adobeio.appregistry.read',
  'adobeio_api',
  'audiencemanager_api',
  'creative_cloud',
  'mps',
  'openid',
  'org.read',
  'pps.read',
  'read_organizations',
  'read_pc',
  'read_pc.acp',
  'read_pc.dma_tartan',
  'session'
].join(',');
