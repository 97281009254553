/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  AccountClusterResponse,
  AuthResponse,
  ExtendedAccountClusterOptions,
  ShellHeaderInput,
  ShellHeaderResponse,
  TokenExchangeInput,
  TokenExchangeResponse
} from '../models/auth';
import {gqlQuery, PREF_APPID, PREF_GROUPID} from '../index';
import {SettingsLevel} from '@adobe/exc-app/settings/SettingsLevel';

const PREF_PARAMS = `appId: "${PREF_APPID}", groupId: "${PREF_GROUPID}", level: "${SettingsLevel.USER}"`;
const GQL_FRAGMENTS = {
  extendedAccountData: `
    fragment extendedFragment on ImsExtendedAccountClusterData {
      data {
        orgs {
          aemInstances {
            domain
            environment
            path
            rootTemplate
            title
            type
          }
          aepRegion
          hasAEP
          imsOrgId
          orgName
          tenantId
        }
        owningOrg {
          aemInstances {
            domain
            title
          }
          aepRegion
          imsOrgId
          hasAEP
          tenantId
          orgName
        }
        exchangeToken {
          token {
            access_token
            expires_in
          }
          profile
        }
        userId
        userType
      }
      next
      preferredLanguages
    }
  `
};

export const RELOAD_QUERY = `
  query shellInitDataQuery($includeAEM: Boolean, $selectedOrg: String) {
    getSettings(${PREF_PARAMS}, settings: {
      coachMarks: {},
      defaultOrg: "",
      lastLoggedInOrg: "",
      lastRecents: {},
      lastSelectedSubOrgs: {},
      shellNav: {},
      theme: "",
      recentSandboxes: [],
      userConsent: {},
      userConsentDismissed: 0,
      userRoles: {},
      userRolesTimeline: {},
      userSandboxes: {}
    }) {
      settings
    }
    getConsentPermissions(useMpsCache: true) {
      last_update_dts
      permissions {
        name
        enabled
        last_update_dts
      }
    }
    imsExtendedAccountClusterData(includeAEM: $includeAEM, selectedOrg: $selectedOrg, ignoreSuppressed: true) {
      ...extendedFragment
    }
  }
  ${GQL_FRAGMENTS.extendedAccountData}
`;

export const LOGIN_QUERY = `
  query shellInitDataQuery($includeAEM: Boolean, $selectedOrg: String) {
    imsExtendedAccountClusterData(includeAEM: $includeAEM, selectedOrg: $selectedOrg, ignoreSuppressed: true) {
      ...extendedFragment
    }
    getSettings(${PREF_PARAMS}, settings: {
      coachMarks: {},
      defaultOrg: "",
      lastLoggedInOrg: "",
      lastRecents: {},
      lastSelectedSubOrgs: {},
      recentSandboxes: [],
      shellNav: {},
      theme: "",
      userConsent: {},
      userConsentDismissed: 0,
      userRoles: {},
      userRolesTimeline: {},
      userSandboxes: {}
    }) {
      settings
    }
    getConsentPermissions(useMpsCache: true) {
      last_update_dts
      permissions {
        name
        enabled
        last_update_dts
      }
    }
    userProfileJson(ignoreImsCache: false)
  }
  ${GQL_FRAGMENTS.extendedAccountData}
`;

export const ACCOUNTS_QUERY = `
  query extendedAccountData($from: String!, $includeAEM: Boolean) {
    imsExtendedAccountClusterData(from: $from, includeAEM: $includeAEM) {
      ...extendedFragment
    }
  }
  ${GQL_FRAGMENTS.extendedAccountData}
`;

export const ACCOUNT_CLUSTER_QUERY = `
  query extendedAccountData($includeAEM: Boolean) {
    imsExtendedAccountClusterData(includeAEM: $includeAEM) {
      ...extendedFragment
    }
  }
  ${GQL_FRAGMENTS.extendedAccountData}
`;

export const SHELL_HEADER_QUERY = `
  query shellHeaderQuery($locale: String, $config_version: Int, $settings: JSON!, $imageKeySize: [Int]!) {
    getSettings(settings: $settings, appId: "pulse", groupId: "notifications") {
      settings
    }
    getPPSProfile {
      images
      encodedImage(imageKey: $imageKeySize)
    }
    userOrgPreferences {
      displayAlert
    }
    timeline(config_version: $config_version, locale: $locale)
    userProfileJson
  }
`;

export const TOKEN_EXCHANGE_QUERY = `
  query userTokenQuery($targetUserId: String!) {
    exchangeToken(targetUserId: $targetUserId) {
      token {
        access_token
        expires_in
      }
      profile
    }
  }
`;

export const initializeShellDataForLogin = (options: ExtendedAccountClusterOptions): Promise<AuthResponse> =>
  gqlQuery<AuthResponse, ExtendedAccountClusterOptions>(LOGIN_QUERY, options, {ignoreErrorsOnPaths: ['getSettings', 'getConsentPermissions']});

export const initializeShellDataForReload = (options: ExtendedAccountClusterOptions): Promise<AuthResponse> =>
  gqlQuery<AuthResponse, ExtendedAccountClusterOptions>(RELOAD_QUERY, options, {ignoreErrorsOnPaths: ['getSettings', 'getConsentPermissions']});

export const getAccountCluster = (options: ExtendedAccountClusterOptions): Promise<AccountClusterResponse> =>
  gqlQuery<AccountClusterResponse, ExtendedAccountClusterOptions>(ACCOUNT_CLUSTER_QUERY, options);

export const getPaginatedAccountClusters = (options: ExtendedAccountClusterOptions): Promise<AccountClusterResponse> =>
  gqlQuery<AccountClusterResponse, ExtendedAccountClusterOptions>(ACCOUNTS_QUERY, options);

export const initializeShellDataForHeader = ({getProfile, ...options}: ShellHeaderInput): Promise<ShellHeaderResponse> =>
  gqlQuery<ShellHeaderResponse, ShellHeaderInput>(
    // If we don't need a fresh profile, remove it from the query.
    getProfile ? SHELL_HEADER_QUERY : SHELL_HEADER_QUERY.replace(/.*userProfileJson.*\n/, ''),
    options,
    {ignoreErrorsOnPaths: ['getPPSProfile', 'getSettings', 'userOrgPreferences', 'timeline']}
  );

export const exchangeToken = (options: TokenExchangeInput): Promise<TokenExchangeResponse> =>
  gqlQuery<TokenExchangeResponse, TokenExchangeInput>(TOKEN_EXCHANGE_QUERY, options);
