/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import metrics from '@adobe/exc-app/metrics';

const EXPIRE_ON_LOGOUT = 0;
// Theshold for storage is 1MB
const THRESHOLD = 1000000;
let compression: typeof import('./compression');

export type WindowStorage = typeof localStorage | typeof sessionStorage;

interface ExpirationDictionary {
  [key: string]: number;
}

export class StorageInstance {
  private readonly expirationDict: ExpirationDictionary;
  private readonly metrics = metrics.create('exc.storage.Storage');
  private readonly storage: WindowStorage;

  constructor(windowStorage: WindowStorage) {
    const expirationDict = windowStorage.getItem('shellStorageExpiration');
    this.expirationDict = expirationDict ? JSON.parse(expirationDict) : {};
    this.storage = windowStorage;
    setInterval(() => this.trackExpiration(), 60000);
  }

  /**
   * Returns item from storage.
   * @param key Key with which to query storage.
   * @returns Promise which resolves to the value in storage.
   */
  async get<T>(key: string): Promise<T | null> {
    let {compressed, json} = this.getFromStorage(key) || {};
    if (!json) {
      return null;
    }
    if (compressed === 'Y') {
      compression = compression || await import('./compression');
      json = compression.decompress(json, key);
    }
    return JSON.parse(json) as T;
  }

  /**
   * Pull data from storage and do some small checks to ensure it's valid.
   * @param key Key with which to query storage.
   * @returns compressed and json values or null.
   */
  getFromStorage(key: string): {compressed: string, json: string} | null {
    const stringValue = this.storage.getItem(key);
    if (!stringValue) {
      return null;
    }
    // Split the compressed/json part from the rest.
    let [compressed, json] = stringValue.split(/\|(.+)/);
    if (!json) {
      this.remove(key);
      return null;
    }
    const expirationTime = this.expirationDict[key];
    // If the key has expired, remove it
    if (expirationTime > 0 && expirationTime < Date.now()) {
      this.metrics.event('storage expiration', `Key ${key} expired at ${expirationTime}`, {expirationTime, key});
      this.remove(key);
      return null;
    }
    return {compressed, json};
  }

  /**
   * Sychronous version of get. Compression is NOT supported in this method.
   * @param key Key with which to query storage.
   * @returns Value in storage.
   */
  getSync<T>(key: string): T | null {
    const {compressed, json} = this.getFromStorage(key) || {};
    return json && compressed === 'N' ? JSON.parse(json) as T : null;
  }

  /**
   * Returns item expiration time for the key.
   * @param expirationInMs Expiration time to be set.
   * @returns 0 if it expires at logout, or the expiration time.
   */
  getExpireTime(expirationInMs?: number): number {
    return expirationInMs ? Date.now() + expirationInMs : EXPIRE_ON_LOGOUT;
  }

  /**
   * Stores the updated expirationDict in storage.
   */
  storeExpirationDictionary(): void {
    try {
      this.storage.setItem('shellStorageExpiration', JSON.stringify(this.expirationDict));
    } catch (domException) {
      if (domException instanceof DOMException) {
        this.metrics.warn('Failed to set expiration dictionary in storage.', {
          error: domException.name
        });
      }
    }
  }

  /**
   * Updates a key
   * @template T
   * @param key Key with which to query storage.
   * @param value T Value must be an object of key value pairs.
   * @param expirationInMs Expiration time for the storage entry.
   * @param compress Whether or not to force compress.
   */
  async update<T>(key: string, value: Partial<T>, expirationInMs?: number, compress?: boolean): Promise<void> {
    let storedValue: Partial<T> = await this.get(key) || {};
    storedValue = {...storedValue, ...value};
    this.set(key, storedValue, expirationInMs, compress);
  }

  /**
   * Sets a key value pair in browser storage.
   * @param key Key with which to query storage.
   * @param value Value to be put into storage
   * @param expirationInMs Expiration time for the storage entry.
   * @param compress Whether or not to force compress.
   */
  async set<T>(key: string, value: T, expirationInMs?: number, compress?: boolean): Promise<void> {
    let json = JSON.stringify(value);
    let compressed = false;
    const expirationTimestamp = this.getExpireTime(expirationInMs);

    if (json.length > THRESHOLD || compress) {
      compression = compression || await import('./compression');
      json = compression.compress(json, key);
      compressed = true;
    }

    try {
      this.storage.setItem(key, `${compressed ? 'Y' : 'N'}|${json}`);
    } catch (domException) {
      if (domException instanceof DOMException) {
        this.metrics.warn('Failed to set item in storage.', {compressed, error: domException.name, key, rawSize: json.length});
      }
    }
    this.storeExpireTime(key, expirationTimestamp);
  }

  /**
   * Removes item from storage.
   * @param key Key with which to query storage.
   */
  remove(key: string): void {
    this.storage.removeItem(key);
    delete this.expirationDict[key];
    this.storeExpirationDictionary();
  }

  /**
   * Clears all values or those determined by a filter function.
   * @param filter Function to filter which keys to clear
   */
  clear(filter?: (key: string) => boolean): void {
    Object.keys(this.expirationDict).forEach(key => {
      if (!filter || filter(key)) {
        this.storage.removeItem(key);
        delete this.expirationDict[key];
      }
    });
    this.storeExpirationDictionary();
  }

  /**
   * Clears values containg an expiration time of 0, denoting that
   * they should be cleared on logout.
   */
  clearOnLogout(): void {
    this.clear((key: string) => this.expirationDict[key] === EXPIRE_ON_LOGOUT);
  }

  /**
   * Stores or updates an expiration time for a key.
   * @param key Key with which to query storage.
   * @param expirationTimestamp Expiration timestamp in ms.
   */
  storeExpireTime(key: string, expirationTimestamp: number): void {
    this.expirationDict[key] = expirationTimestamp;
    this.storeExpirationDictionary();
  }

  /**
   * Function to check which storage entries have expired and remove them.
   */
  trackExpiration(): void {
    Object.keys(this.expirationDict).forEach(key => {
      const value = this.expirationDict[key];
      if (value > 0 && value < Date.now()) {
        this.metrics.event('storage expiration', `Key ${key} expired at ${value}`, {expirationTime: value, key});
        this.storage.removeItem(key);
        delete this.expirationDict[key];
      }
    });
    this.storeExpirationDictionary();
  }
}

let local: StorageInstance;
let session: StorageInstance;

try {
  local = new StorageInstance(localStorage);
  session = new StorageInstance(sessionStorage);
} catch {
  // Unified Shell will show an error if storage is blocked by the browser.
  // This code is needed to make sure the import of this module does not break execution.
  local = {} as StorageInstance;
  session = {} as StorageInstance;
}

export const storage = {
  local,
  session
};
