"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to store the authorization code fields
 */
var AuthorizationCode = /** @class */ (function () {
    /**
     * this class is used to create a AuthorizationCode instance based on authorization server response
     * @param authProps {object} represents the authorization server response
     */
    function AuthorizationCode(authProps) {
        /**
         * represents the client id associated with this token fields
         */
        this.client_id = '';
        /**
         * represents the scope used to retrieve the token
         */
        this.scope = '';
        /**
         * represents the code recived during the authorization
         */
        this.code = '';
        /**
         * represents the state used during initialize
         */
        this.state = null;
        /**
         * represents the code_verifier used to generate the code_challenge (used during authorization flow)
         */
        this.code_verifier = '';
        /**
         * represents the other properties from url
         */
        this.other = null;
        var code = authProps.code, state = authProps.state, client_id = authProps.client_id, scope = authProps.scope, verifier = authProps.verifier, other = __rest(authProps, ["code", "state", "client_id", "scope", "verifier"]);
        this.state = state;
        this.client_id = client_id;
        this.code = code;
        this.scope = scope;
        this.code_verifier = verifier;
        this.other = other;
    }
    return AuthorizationCode;
}());
exports.AuthorizationCode = AuthorizationCode;
