"use strict";
// proudly found here: https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
    return (item !== null && item !== undefined && typeof item === 'object' && !Array.isArray(item));
}
exports.isObject = isObject;
/**
   * Deep merge two objects.
   * @param target
   * @param source
   */
function merge(target, source) {
    if (target === null || target === undefined) {
        return source;
    }
    if (target === source) {
        return target;
    }
    if (!isObject(target)) {
        return target;
    }
    var output = Object.assign({}, target);
    if (isObject(source)) {
        Object.keys(source).forEach(function (key) {
            var _a, _b;
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, (_a = {}, _a[key] = source[key], _a));
                else
                    output[key] = merge(target[key], source[key]);
            }
            else {
                Object.assign(output, (_b = {}, _b[key] = source[key], _b));
            }
        });
    }
    return output;
}
exports.merge = merge;
