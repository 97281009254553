/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {SHELL_PROD_SCOPES, SHELL_STAGE_SCOPES} from '@exc/auth/src/utils/scopes';

export interface IMSConfig {
  client_id: string;
  environment: string;
  redirect_uri: string;
  scope: string;
}

export const getRedirectUri = (win: Window = window): string => {
  const {href} = win.location;
  const domain = /https?:\/\/(.+\.)experience/.exec(href);
  return domain ? href.replace(domain[1], '') : href;
};

export const getIMSConfig = (environment: string, clientId: string, scopes?: string): IMSConfig => ({
  client_id: clientId,
  environment,
  redirect_uri: getRedirectUri(),
  scope: scopes || (environment === 'prod' ? SHELL_PROD_SCOPES : SHELL_STAGE_SCOPES)
});
