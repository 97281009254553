/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ConsentPermissions} from '@adobe/exc-app/consent';
import type {Metrics} from '@adobe/exc-app/metrics';
import {storage} from '@exc/storage';

const CONSENT_STORAGE_KEY = 'shellUserConsent';
const STORAGE_DURATION = 1000 * 60 * 60 * 24 * 365 * 10; // 10 years

export const getCachedConsent = (): ConsentPermissions | null => {
  try {
    return storage.local.getSync<ConsentPermissions>(CONSENT_STORAGE_KEY);
  } catch {
    return null;
  }
};

export const onConsent = (
  data: ConsentPermissions | null,
  metrics: Metrics,
  updateStorage = true
): void => {
  if (typeof data?.adobeUsageDataCollection === 'boolean') {
    (window as any).adobeMetrics?.optOut?.(!data.adobeUsageDataCollection);
    metrics.event(data.adobeUsageDataCollection ? 'EIM_OPT_IN' : 'EIM_OPT_OUT');
  }
  data && updateStorage && updateCachedConsent(data);
};

export const updateCachedConsent = (data: Partial<ConsentPermissions>): Promise<void> => {
  const cached = getCachedConsent();
  return storage.local.set(CONSENT_STORAGE_KEY, {...cached, ...data}, STORAGE_DURATION);
};
