/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {AdobeIMS} from '@identity/imslib/adobe-ims/AdobeIMS';
import {extractTokenMetadata} from '@exc/shared';
import {getIMSConfig} from './IMSConfig';
import type {IAdobeIdData} from '@identity/imslib/adobe-id/IAdobeIdData';
import {IEnvironment} from '@identity/imslib/adobe-id/IEnvironment';
import type {IErrorType} from '@identity/imslib/adobe-id/IErrorType';
import type {ImsProfile} from '@adobe/exc-app/ims/ImsProfile';
import type {ITokenInformation} from '@identity/imslib/adobe-id/custom-types/CustomTypes';

/* eslint-disable sort-keys */
const ENVS: Record<string, IEnvironment> = {
  test: IEnvironment.STAGE,
  dev: IEnvironment.STAGE,
  qa: IEnvironment.STAGE,
  stage: IEnvironment.STAGE,
  prod: IEnvironment.PROD
};
/* eslint-enable sort-keys */

export interface IMSObjects {
  adobeid: IAdobeIdData,
  adobeIMS: AdobeIMS,
  storedEvents: string[]
}

export interface GetIMSObjectsParams {
  clientId: string;
  env?: string;
  profile?: ImsProfile;
  scopes?: string;
  token?: string;
}

const storedEvents: string[] = [];
let imsPromise: Promise<IMSObjects> | undefined;

const handleEvent = <T>(event: string, store = true, detail?: T): void => {
  store && storedEvents.push(event);
  document.dispatchEvent(new CustomEvent(event, {detail}));
};

const getIMS = async (adobeid: IAdobeIdData, token: string, profile?: ImsProfile): Promise<IMSObjects> => {
  const adobeIMS = new AdobeIMS(adobeid);
  if (token) {
    const {expires_in, sid = ''} = extractTokenMetadata(token) || {};
    expires_in && adobeIMS.setStandAloneToken({expirems: expires_in, sid, token});
    profile && adobeIMS['profileService'].saveProfileToStorage(profile);
  }
  await adobeIMS.initialize();
  return {adobeid, adobeIMS, storedEvents};
};

export function getIMSObjects({clientId, env = 'dev', profile, scopes, token = ''}: GetIMSObjectsParams): Promise<IMSObjects> {
  if (!imsPromise) {
    const config = getIMSConfig(ENVS[env], clientId, scopes);

    const adobeid: IAdobeIdData = {
      ...config,
      locale: window.navigator.language,
      onAccessToken: (token: ITokenInformation) => handleEvent('auth.accesstoken', false, token),
      onAccessTokenHasExpired: () => handleEvent('auth.accesstokenexpired', true),
      onError: (error: IErrorType, message: any) => handleEvent('auth.error', true, {error, message}),
      onReady: null,
      onReauthAccessToken: null
    } as IAdobeIdData;

    imsPromise = getIMS(adobeid, token, profile);
  }

  return imsPromise;
}
