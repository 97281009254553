"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IEnvironment_1 = require("../../adobe-id/IEnvironment");
var CheckTokenEndpoint_1 = require("./CheckTokenEndpoint");
/**
 * class used to store the variables used for ims flow
 */
var Environment = /** @class */ (function () {
    function Environment() {
        /**
         * @property {String} Represents the base url used on api (back-end) call in case of getProfile, getUserInfo and validateToken;
         */
        this.baseUrlAdobe = '';
        /**
         * @property {string} Represents the base url used on api (back-end) call in case of logoutToken, checkStatus, listSocialProviders and exchangeIjt;
         */
        this.baseUrlServices = '';
        /**
         * @property {object} Represents the base url used on api (back-end) call in case of checkToken;
         */
        this.checkTokenEndpoint = new CheckTokenEndpoint_1.CheckTokenEndpoint();
        /**
         * @property {string} this parameter is passed to redirect uri during a sign in or sign out operation
         */
        this.jslibver = 'v2-v0.31.0-2-g1e8a8a8';
    }
    Environment.prototype.loadEnvironment = function (environment, useProxy, hostname) {
        if (useProxy === void 0) { useProxy = false; }
        if (hostname === void 0) { hostname = ''; }
        var isStage = environment === IEnvironment_1.IEnvironment.STAGE;
        if (isStage) {
            this.baseUrlAdobe = 'https://ims-na1-stg1.adobelogin.com';
            this.baseUrlServices = 'https://adobeid-na1-stg1.services.adobe.com';
        }
        else {
            this.baseUrlAdobe = 'https://ims-na1.adobelogin.com';
            this.baseUrlServices = 'https://adobeid-na1.services.adobe.com';
        }
        this.checkTokenEndpoint = CheckTokenEndpoint_1.CheckTokenEndpoint.computeEndpoint(useProxy, hostname, isStage, this.baseUrlServices);
    };
    return Environment;
}());
exports.default = new Environment();
