"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enumeration values used for available grant types
 *
 * Default value is token
 */
var IGrantTypes;
(function (IGrantTypes) {
    /**
     * token
     */
    IGrantTypes["token"] = "token";
    /**
     * authorization code
     */
    IGrantTypes["code"] = "code";
})(IGrantTypes = exports.IGrantTypes || (exports.IGrantTypes = {}));
