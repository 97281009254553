"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to store the modal settings
 */
var PopupSettings = /** @class */ (function () {
    function PopupSettings(data) {
        if (data === void 0) { data = {}; }
        this.title = 'Adobe ID';
        this.width = 600;
        this.top = 100;
        this.left = 100;
        var _a = data.title, title = _a === void 0 ? 'Adobe ID' : _a, _b = data.width, width = _b === void 0 ? 600 : _b, _c = data.height, height = _c === void 0 ? 700 : _c, _d = data.top, top = _d === void 0 ? 100 : _d, _e = data.left, left = _e === void 0 ? 100 : _e, allowedOrigin = data.allowedOrigin;
        this.title = title;
        this.width = width;
        this.height = height;
        this.top = top;
        this.left = left;
        this.allowOrigin = allowedOrigin;
    }
    return PopupSettings;
}());
exports.PopupSettings = PopupSettings;
