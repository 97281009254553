/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {APIMode} from '@adobe/exc-app/RuntimeConfiguration';
import {getIMSConfig, IMSConfig} from './IMSConfig';

interface Endpoints {
  apiGateway: string;
  cdn: string;
  ioGateway: string;
  regionGatewayIoMap: Record<string, string>;
  translation: string;
  xql: string;
}

export interface Config {
  apiMode: APIMode;
  cdn: string;
  endpoints: Endpoints;
  environment: string;
  iframeUseSameOrigin?: boolean;
  favicon: string | undefined;
  ims: IMSConfig;
  region?: string;
  scripts: any;
  scriptsPath: string;
  serviceEnvironment: string;
}

type ConfigFragment = Partial<Config> & {endpoints: Endpoints};

const BASE_CONFIG: ConfigFragment = {
  endpoints: {
    apiGateway: 'https://exc-unifiedcontent.experience-qa.adobe.net',
    cdn: 'https://experience-qa.adobe.net', // QA does not have a CDN.
    ioGateway: 'https://experience-qa.adobe.io',
    regionGatewayIoMap: {
      va7: 'https://experience-qa.adobe.io'
    },
    translation: 'https://mt-stage.adobe.io',
    xql: 'https://platform-int.adobe.io/data/xql/graphql'
  }
};

const PROD_CONFIG: ConfigFragment = {...BASE_CONFIG, endpoints: {
  apiGateway: 'https://exc-unifiedcontent.experience.adobe.net',
  cdn: 'https://cdn.experience.adobe.net',
  ioGateway: 'https://experience.adobe.io',
  regionGatewayIoMap: {
    aus5: 'https://experience-aus5.adobe.io',
    nld2: 'https://experience-nld2.adobe.io',
    va7: 'https://experience-va7.adobe.io'
  },
  translation: 'https://mt.adobe.io',
  xql: 'https://platform.adobe.io/data/xql/graphql'
}};

/* eslint-disable sort-keys */
const ENV_TO_IMS: Record<string, string> = {
  test: 'stage',
  dev: 'stage',
  qa: 'stage',
  stage: 'stage',
  prod: 'prod'
};
/* eslint-enable sort-keys */

export const getServiceEnvironment = (env: string, imsEnv: string): string => {
  if (env === 'prod' && imsEnv === 'stage') {
    return 'stage';
  }
  return imsEnv === 'prod' ? 'prod' : env;
};

export const getConfiguration = (config: Partial<Config>, clientId: string, imsEnv = ''): Config => {
  const {
    apiMode = 'afd',
    cdn = '',
    environment = 'dev',
    iframeUseSameOrigin = false,
    scripts = {},
    scriptsPath = '/assets',
    region = undefined
  } = config;

  const faviconDefault: HTMLLinkElement|null = document.querySelector('link[rel*="icon"]');
  const configEnvMap: Record<string, ConfigFragment> = {
    dev: BASE_CONFIG,
    prod: PROD_CONFIG,
    qa: BASE_CONFIG,
    stage: {...BASE_CONFIG, endpoints: {
      ...BASE_CONFIG.endpoints,
      apiGateway: 'https://exc-unifiedcontent.experience-stage.adobe.net',
      cdn: 'https://cdn.experience-stage.adobe.net',
      ioGateway: 'https://experience-stage.adobe.io',
      regionGatewayIoMap: {
        nld2: 'https://experience-stage-nld2.adobe.io',
        va7: 'https://experience-stage-va7.adobe.io'
      },
      xql: 'https://platform-stage.adobe.io/data/xql/graphql'
    }},
    test: BASE_CONFIG
  };

  if (!(environment in configEnvMap)) {
    throw new Error(`Invalid environment ${environment}`);
  }

  const defaultConfig = {
    environment,
    favicon: faviconDefault ? faviconDefault.href : undefined,
    iframeUseSameOrigin,
    ims: getIMSConfig(imsEnv || ENV_TO_IMS[environment], clientId),
    serviceEnvironment: getServiceEnvironment(environment, imsEnv)
  };
  const environmentConfig: ConfigFragment = imsEnv ? configEnvMap[imsEnv] : configEnvMap[environment];

  return {apiMode, cdn, region, scripts, scriptsPath, ...defaultConfig, ...environmentConfig};
};
