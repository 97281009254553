"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enumeration values used for ims error types
 */
/**
 * Enumeration values used for ims error types
 */
var IErrorType;
(function (IErrorType) {
    /**
     * error triggered in case of exception on adobe ims initialize
     */
    IErrorType["INITIALIZE_ERROR"] = "initialize_error";
    /**
     * http exception type
     */
    IErrorType["HTTP"] = "http";
    /**
     * fragment exception type
     */
    IErrorType["FRAGMENT"] = "fragment";
    /**
     * csrf exception type
     */
    IErrorType["CSRF"] = "csrf";
    /**
     * error triggered when get token method is called using a different api than the ones allowed
     */
    IErrorType["NOT_ALLOWED"] = "not_allowed";
    /**
     * exception triggered when the profile api throws exception
     */
    IErrorType["PROFILE_EXCEPTION"] = "profile_exception";
    /**
     * token expired error
     */
    IErrorType["TOKEN_EXPIRED"] = "token_expired";
    /**
      * social providers error type
      */
    IErrorType["SOCIAL_PROVIDERS"] = "SOCIAL_PROVIDERS";
    /**
    * ride exception sent to the client in case ther is no jump
    */
    IErrorType["RIDE_EXCEPTION"] = "ride_exception";
})(IErrorType = exports.IErrorType || (exports.IErrorType = {}));
