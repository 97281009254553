"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdobeIdKey = 'adobeid';
exports.AdobeIMSKey = 'adobeIMS';
exports.AdobeImsFactory = 'adobeImsFactory';
exports.DEFAULT_LANGUAGE = 'en_US';
var STORAGE_MODE;
(function (STORAGE_MODE) {
    STORAGE_MODE["LocalStorage"] = "local";
    STORAGE_MODE["SessionStorage"] = "session";
    STORAGE_MODE["MemoryStorage"] = "memory";
})(STORAGE_MODE = exports.STORAGE_MODE || (exports.STORAGE_MODE = {}));
exports.HEADERS = {
    AUTHORIZATION: 'Authorization',
    X_IMS_CLIENT_ID: 'X-IMS-ClientId',
    RETRY_AFTER: 'Retry-after',
};
exports.PROFILE_STORAGE_KEY = 'adobeid_ims_profile';
exports.TOKEN_STORAGE_KEY = 'adobeid_ims_access_token';
exports.ON_IMSLIB_INSTANCE = 'onImsLibInstance';
exports.ASK_FOR_IMSLIB_INSTANCE_DOM_EVENT_NAME = 'getImsLibInstance';
