"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to store the xhr error response
 */
var ApiResponse = /** @class */ (function () {
    function ApiResponse(status, message) {
        this.status = 0;
        this.data = '';
        this.status = status;
        this.data = this.toJson(message);
    }
    ApiResponse.prototype.toJson = function (value) {
        try {
            if (typeof value !== 'string') {
                return value;
            }
            return JSON.parse(value);
        }
        catch (_a) {
            return value;
        }
    };
    return ApiResponse;
}());
exports.ApiResponse = ApiResponse;
