"use strict";
/**
 * class used for caching the api calls for a second.
 * The flow is:
 * 1. make api call from ImsLib
 * 2. if the Debouncer cache already contains a response for that api/parameters -> return the cashed response
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Debouncer = /** @class */ (function () {
    function Debouncer() {
        var _this = this;
        /**
         * debounce time value; one second
         */
        this.DEBOUNCE_TIME = 1000;
        /**
         * dictionary with all executed methods for the last second
         */
        this.cache = {};
        /**
         *
         * @param url {String} - url used to make the api
         * @param bodyParams {String} - the hash associated with the request parameters
         * @param response {ApiResponse} - data received as a http response
         */
        this.storeApiResponse = function (url, bodyParams, response) {
            if (url === void 0) { url = ''; }
            if (bodyParams === void 0) { bodyParams = ''; }
            _this.cacheApiResponse(url, bodyParams, response);
        };
    }
    /**
     * starts to execute a specific API. if there is a cached response, it will be returned
     * @param url - api url
     * @param parameters - represents the parameters used to call the api
     */
    Debouncer.prototype.getCachedApiResponse = function (url, parameters) {
        if (parameters === void 0) { parameters = ''; }
        var hash = typeof parameters === 'string' ? parameters : JSON.stringify(parameters);
        var cachedUrlValues = this.cache[url];
        if (!cachedUrlValues) {
            return null;
        }
        var cachedHash = cachedUrlValues[hash];
        if (!cachedHash) {
            return null;
        }
        return cachedHash.data;
    };
    /**
     *
     * @param url {String} - url used to make the api
     * @param bodyParams {String} - the hash associated with the request parameters
     * @param response {ApiResponse} - data received as a http response
     */
    Debouncer.prototype.cacheApiResponse = function (url, bodyParams, response) {
        if (url === void 0) { url = ''; }
        if (bodyParams === void 0) { bodyParams = ''; }
        var cachedUrlValues = this.cache[url];
        if (!cachedUrlValues) {
            cachedUrlValues = {};
            this.cache[url] = cachedUrlValues;
        }
        var timerId = this.createClearCachedDataTimer(url, bodyParams);
        cachedUrlValues[bodyParams] = {
            timerId: timerId,
            data: __assign({}, response),
        };
    };
    /**
     * create the timer for a url/hash
     * @param url - {string} - represents the url used to make the http call
     * @param hash {String} - the hash associated with the request parameters
     */
    Debouncer.prototype.createClearCachedDataTimer = function (url, hash) {
        var _this = this;
        var timerId = setTimeout(function () {
            var cachedUrlValues = _this.cache[url] || {};
            var methodValues = cachedUrlValues[hash];
            if (!methodValues) {
                return;
            }
            if (methodValues && methodValues.timerId) {
                clearTimeout(methodValues.timerId);
                delete cachedUrlValues[hash];
            }
        }, this.DEBOUNCE_TIME);
        return timerId;
    };
    return Debouncer;
}());
exports.default = new Debouncer();
