"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Wrapper for <code>console</code>. Checks <code>console</code> is available and <code>debug</code> Mode is enabled.
 *
 * @exports log
 *
 */
var Log = /** @class */ (function () {
    function Log() {
        var _this = this;
        this.logEnabled = false;
        /**
         * Wrapper function.
         *
         * @param {String} method - Which <code>console</code> method to use.
         * @param {*} args - Arguments object
         *
         * @private
         */
        this.print = function (method, args) {
            if (!_this.logEnabled) {
                return;
            }
            method.apply(void 0, __spread(args));
        };
        /**
         * Writes an error to the console when the evaluated expression is false.
         *
         * @example
         * log.assert(1 == 2, "Counting is hard.");
         *
         * @param {Boolean} expression - Expression to evaluate.
         * @param {String} message - Message to print if <code>epxression</code> is <code>false</code>.
         *
         * @static
         */
        this.assert = function (val, message) {
            _this.print(console.assert, [val, message]);
        };
        /**
         * Writes an error to the console when the evaluated expression is false.
         *
         * @example
         * log.assert(1 == 2, "Counting is hard.");
         *
         * @param {Boolean} expression - Expression to evaluate.
         * @param {String} message - Message to print if <code>epxression</code> is <code>false</code>.
         *
         * @static
         */
        this.assertCondition = function (condition, message) {
            if (!condition()) {
                _this.print(console.error, [message]);
            }
        };
        /**
         * Prints a message similar to <code>console.log()</code>, styles the message like an error, and includes a stack
         * trace from where the method wasval, called.
         *
         * @see {@link https://developers.google.com/web/tools/chrome-devtools/console/#filtering_the_console_output | Filtering the Console output}
         *
         * @example
         * log.error("Define window.adobeid", "would you?!");
         * @param {String} message - Message to print.
         */
        this.error = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.print(console.error, args);
        };
        /**
         * logs a warnig message
         */
        this.warn = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.print(console.warn, args);
        };
        /**
         * Prints a message like <code>console.log()</code> but also shows an icon (blue circle with white "i") next to the
         * output.
         *
         * <code>console.info()</code> can be filtered in the Console, whereas <code>console.log()</code> can not.
         *
         * @example
         * log.info("Imslib.js Ready", "to Rumble");
         *
         * @param {String} message - Message to print.
         *
         * @static
         */
        this.info = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.print(console.info, args);
        };
    }
    Log.prototype.enableLogging = function () {
        this.logEnabled = true;
    };
    Log.prototype.disableLogging = function () {
        this.logEnabled = false;
    };
    return Log;
}());
exports.default = new Log();
