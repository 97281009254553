"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to pass the http errors to external applications
 */
var HttpErrorResponse = /** @class */ (function () {
    function HttpErrorResponse(data) {
        var error = data.error, _a = data.retryAfter, retryAfter = _a === void 0 ? 0 : _a, _b = data.message, message = _b === void 0 ? '' : _b;
        this.error = error;
        this.retryAfter = retryAfter;
        this.message = message;
    }
    return HttpErrorResponse;
}());
exports.HttpErrorResponse = HttpErrorResponse;
