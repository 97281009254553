"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to store the ride errors after a check token call
 */
var RideException = /** @class */ (function () {
    function RideException(code, jump, isPbaExpiredIdleSessionWorkaround) {
        if (isPbaExpiredIdleSessionWorkaround === void 0) { isPbaExpiredIdleSessionWorkaround = false; }
        /**
         * new redirect url in case of a ride error
         */
        this.jump = '';
        this.code = code;
        this.jump = jump;
        this.isPbaExpiredIdleSessionWorkaround = isPbaExpiredIdleSessionWorkaround;
    }
    return RideException;
}());
exports.RideException = RideException;
