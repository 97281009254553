/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {parse} from 'querystring';

interface PathTenant {
  path?: string;
  tenant?: string;
}

interface HashPathOptions {
  hash?: string;
  removePrecedingSlash?: boolean;
}

export interface SearchParams {
  [key: string]: string;
}

/**
 * Removes #/ from the hash.
 * @param options - Object containing optional hash and option to remove first /.
 * @returns Cleaned hash.
 */
export const hashToPath = (options: HashPathOptions = {}): string => {
  const hash = options.hash !== undefined ? options.hash : window.location.hash;
  return options.removePrecedingSlash ? hash.replace(/^#\//, '') : hash.replace(/^#/, '');
};

/**
 * Get query params from string or window location.
 * @param {string} query - Full query string.
 * @returns {Object} Query params and values.
 */
export const getQueryParams = (query?: string): SearchParams => parse((query || window.location.search).replace(/^\?/, '')) as SearchParams;

/**
 * Get the value of a 'shell_' config query param.
 * @param {string} path - Name of shell config param.
 * @returns {string} Value of the query parameter.
 */
export const getQueryValue = (param: string, prefix = 'shell'): string => getQueryParams()[`${prefix}_${param}`];

/**
 * Cleans token information from the url and returns the new hash.
 * @param hash - The hash string to clean.
 * @returns Cleaned path.
 */
export const cleanTokenFromHash = (hash: string): string | undefined => {
  const tokenRegex = /#?access_token=[^&]*&token_type=bearer&expires_in=\d*/;
  if (hash.match(tokenRegex)) {
    return hash.replace(tokenRegex, '');
  }
};

export const getTenantAndPath = (path: string): PathTenant => {
  let tenant;
  const matches = path.match(/^(\/@([^/]*))?(\/.*)$/);
  if (matches) {
    path = matches[3];
    tenant = matches[2];
  }
  return {path, tenant};
};

export const getCurrentTenantAndPath = (): PathTenant => {
  const {hash, origin} = window.location;
  const path = hashToPath({hash});
  if (!path.startsWith('/')) {
    // If getCurrentTenantAndPath is called before "createHashHistory" is called
    // the hash might not start with /. This can happen right after login where hash
    // has the form of #old_hash=xxx - As a future improvement this method can try to
    // extract the tenant from old_hash, this is already done in prefetch-token.js
    return getTenantAndPath('/');
  }
  // Now that we have correct hash value, we can convert it into a path and
  // then parse it to find the tenant and path properties.
  const {pathname} = new URL(`${origin}${path}`);
  return getTenantAndPath(pathname);
};
