/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {Config} from './utils/config';
import {getErrorMessage} from '@exc/shared';

const GEO_HEADER = 'x-geo';
const PING_ENDPOINT = '/check/ping.json';
const PING_TIMEOUT = 20000;
const MAX_DELAY = 8000;

export interface PingCDNResult {
  country?: string;
  duration?: number;
  errorDescription?: string;
  success: boolean;
}

let pingPromise: Promise<PingCDNResult>|undefined;
let cdn = '';

export const setPingEndpoint = (config: Config) => {
  // We should only ping Front Door, hence using API Gateway instead of CDN.
  // This is because Cloudfront does not have a ping endpoint yet.
  cdn = config.endpoints.apiGateway;
};

const ping = async (pingTimeout = PING_TIMEOUT): Promise<PingCDNResult> => {
  const controller = new AbortController();
  const signal = controller.signal;
  let country = '';
  const start = Date.now();
  let duration = 0;
  const timeout = setTimeout(() => controller.abort(), pingTimeout);
  try {
    // Use Date.now() for cache busting
    const url = `${cdn}${PING_ENDPOINT}?cacheBust=${start}`;
    const res = await fetch(url, {signal});
    clearTimeout(timeout);
    duration = Date.now() - start;
    country = res.headers.get(GEO_HEADER) || '';
    if (res.ok) {
      return {
        country,
        duration,
        success: true
      };
    }
    return {
      country,
      duration,
      errorDescription: `${res.status} - ${res.statusText}`,
      success: false
    };
  } catch (err) {
    clearTimeout(timeout);
    duration = duration || (Date.now() - start);
    let errorDescription = getErrorMessage(err);
    if (controller.signal.aborted) {
      errorDescription = `Aborted after ${PING_TIMEOUT}ms`;
    }
    return {
      duration,
      errorDescription,
      success: false
    };
  }
};

export const checkCDN = (): Promise<PingCDNResult> => {
  if (!pingPromise) {
    pingPromise = ping();
  }
  return pingPromise;
};

export const waitForPingSuccess = async (onSuccess: () => void): Promise<void> => {
  let inPing = false;
  let delay = 1000;

  const pingOnce = async () => {
    if (!inPing) {
      inPing = true;
      try {
        const {success} = await ping(5000);
        inPing = false;
        if (success) {
          return onSuccess();
        }
      } catch {
        inPing = false;
      }
      delay = Math.min(delay * 2, MAX_DELAY);
      setTimeout(pingOnce, delay);
    }
  };
  setTimeout(pingOnce, delay);
};
