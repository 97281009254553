"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IErrorType_1 = require("../adobe-id/IErrorType");
/**
 * class used to trigger profile errors
 */
var ProfileException = /** @class */ (function () {
    function ProfileException(message) {
        this.message = null;
        this.errorType = IErrorType_1.IErrorType.PROFILE_EXCEPTION;
        this.message = message;
    }
    return ProfileException;
}());
exports.ProfileException = ProfileException;
