"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to encapsulate the token expired message
 */
var TokenExpiredException = /** @class */ (function () {
    function TokenExpiredException(exception) {
        this.exception = null;
        this.exception = exception;
    }
    return TokenExpiredException;
}());
exports.TokenExpiredException = TokenExpiredException;
