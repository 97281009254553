/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getErrorMessage} from '@exc/shared';
import {getHtmlFromWorker, isWorkerActivated} from '../../utils/workerUtils';
import {getQueryParams, getQueryValue} from '@exc/url/query';
import {isSkyline} from '@exc/url/skyline';
import Metrics, {Level} from '@adobe/exc-app/metrics';
import {THUNDERBIRD_ROOT} from '../../constants';

/** Timeout for service worker to fetch and check HTML */
export const ONE_HOUR = 60 * 60 * 1000;
export const MANIFEST_EP = '/service-worker/manifest';
export const SW_CACHE_NAME = 'unifiedShellCache';
const THUNDERBIRD_SCOPE = `${THUNDERBIRD_ROOT}/`;

export default class ServiceWorkerRegisterService {
  private static SW_DISABLED = false;
  private readonly metrics = Metrics.create('exc.core.services.ServiceWorkerRegisterService');
  private isActive = false;
  private activeLogged = false;
  private async checkDisabled(): Promise<void> {
    const queryValue = getQueryValue('sw_enabled');
    if (queryValue === 'false' || isSkyline()) {
      ServiceWorkerRegisterService.SW_DISABLED = true;
    }
    if (queryValue === 'true') {
      ServiceWorkerRegisterService.SW_DISABLED = false;
    }
  }

  private logRegistration(registration: ServiceWorkerRegistration) {
    if (!this.activeLogged) {
      if (registration.active) {
        this.activeLogged = true;
        this.metrics.event('ServiceWorker.active', {
          navigationPreload: !!registration.navigationPreload,
          state: registration.active.state
        });
      }
      if (registration.installing) {
        this.metrics.event('ServiceWorker.installing');
      }
      if (registration.waiting) {
        this.metrics.event('ServiceWorker.waiting');
      }
    }
  }

  /**
   * Registers service worker.
   */
  public async registerServiceWorker(): Promise<void> {
    await this.checkDisabled();
    if (ServiceWorkerRegisterService.SW_DISABLED) {
      this.metrics.event('App.serviceWorkerDisabled');
      return;
    }

    if (navigator.serviceWorker) {
      let sw = `${location.origin}/sw.js`;
      const version = getQueryParams()['unified-shell_version'];

      if (version) {
        sw += `?unified-shell_version=${version}`;
      }

      if (!this.isActive || navigator.serviceWorker.controller?.scriptURL !== sw) {
        try {
          const [registration] = await Promise.all([
            navigator.serviceWorker.register(sw, {scope: '/'}),
            navigator.serviceWorker.register(sw, {scope: THUNDERBIRD_SCOPE})
          ]);
          this.isActive = !!registration.active;
          this.logRegistration(registration);
        } catch (err) {
          this.metrics.event(
            'ServiceWorker.registrationError',
            {level: Level.ERROR},
            {error: getErrorMessage(err)}
          );
        }
      } else {
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length < 2) {
          navigator.serviceWorker.register(sw, {scope: THUNDERBIRD_SCOPE});
        }
      }
    }
  }

  /**
   * Unregisters the service worker.
   */
  public async unregister(): Promise<void> {
    const {serviceWorker} = navigator;

    ServiceWorkerRegisterService.SW_DISABLED = true;
    if (serviceWorker) {
      const registrations = await serviceWorker.getRegistrations();
      for (const registration of registrations) {
        registration.unregister().then(success => {
          if (success) {
            this.metrics.event('App.serviceWorkerUnregistered');
          } else {
            this.metrics.event(
              'App.serviceWorkerUnregistrationFail',
              {level: Level.ERROR}
            );
          }
        });
      }
    }
  }

  /**
   * Loads the client side code that operates the service worker.
   */
  public load(): void {
    const swEnabled = getQueryValue('sw_enabled');
    const {serviceWorker} = navigator;

    if (serviceWorker) {
      // Get HTML from Service Worker.
      if (serviceWorker.controller?.state === 'activated') {
        this.isActive = true;
        getHtmlFromWorker();
      }
      serviceWorker.ready?.then(registration => {
        if (!this.isActive) {
          this.isActive = !!registration.active;
          getHtmlFromWorker();
        }
        this.logRegistration(registration);
      });

      if (swEnabled === 'false') {
        this.unregister();
        return;
      }

      serviceWorker.addEventListener('message', event => {
        const {additionalData, eimEvent, message, level = Level.INFO} = event.data;
        if (eimEvent && message) {
          this.metrics.event(`ServiceWorker.${eimEvent}`, message, additionalData, {level});
        }
      });
      window.addEventListener('load', () => this.registerServiceWorker());
    } else {
      this.metrics.event('ServiceWorker.NotSupported', {level: Level.WARN});
    }
  }

  /**
   * Prepares Service Worker before user redirects to SUSI in order to improve post login load time.
   * If Service Worker is active already, check manifest to download new version during login.
   * If this is a user's first visit, register the worker.
   */
  public async prepareForLogin() {
    if (isWorkerActivated()) {
      const registerPromise = navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations.length < 2) {
          return this.registerServiceWorker();
        }
      });
      return Promise.all([fetch(MANIFEST_EP), registerPromise]);
    }
    const swCacheExists = window.caches && await caches.has(SW_CACHE_NAME);
    // If the cache already exists, perhaps the user disabled the service worker intentionally.
    // We won't register in that case.
    if (!swCacheExists) {
      return this.registerServiceWorker();
    }
  }
}
