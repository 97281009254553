"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ObjectUtil_1 = require("../util/ObjectUtil");
/**
 * class used to store the helper functions
 */
var ApiHelpers = /** @class */ (function () {
    function ApiHelpers() {
        /**
       * Checks [adobeid.api_parameters]{@link adobeid} for custom parameters for an API.
       * @param apiName - represents the used api name
       * @param apiParameters - represents the parameters set from outside for api endpoints read from adobeId
       * @returns {!Object}
       */
        this.getCustomApiParameters = function (apiParameters, apiName) {
            return apiParameters[apiName] || {};
        };
    }
    /**
     *
     * @param externalParameters external parameters received outside of the library
     * @param apiParameters the api parameters from AdobeId data
     * @param apiName api name
     * @returns IDictionary representing the merged properties
     */
    ApiHelpers.prototype.mergeExternalParameters = function (externalParameters, apiParameters, apiName) {
        return ObjectUtil_1.merge(this.getCustomApiParameters(apiParameters, apiName), externalParameters);
    };
    /***
     * @param value {String} represents the
     */
    ApiHelpers.prototype.toJson = function (value) {
        try {
            if (typeof value !== 'string') {
                return value;
            }
            return JSON.parse(value);
        }
        catch (_a) {
            return null;
        }
    };
    return ApiHelpers;
}());
exports.default = new ApiHelpers();
