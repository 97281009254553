"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to encapsulate the token and expiration ms;
 * this values are passed from outside of the library by using adobeid
 */
var StandaloneToken = /** @class */ (function () {
    function StandaloneToken(data) {
        this.token = '';
        /**
         * representing the session identifier
         */
        this.sid = '';
        this.expirems = 0;
        var token = data.token, expirems = data.expirems;
        this.token = token;
        this.expirems = expirems;
    }
    return StandaloneToken;
}());
exports.StandaloneToken = StandaloneToken;
