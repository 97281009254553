/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {UnifiedShellConfig} from '../models/UnifiedShellConfig';

let initialHtmlPromise: Promise<Response>|undefined = undefined;

export const getWindowConfig = (): UnifiedShellConfig => (window as any).config as UnifiedShellConfig;

export const isWorkerActivated = (): boolean => navigator.serviceWorker?.controller?.state === 'activated';

export const getHtmlFromWorker = (): Promise<Response> => {
  const {lastModified, spaVersion} = getWindowConfig();
  const endpoint = `/service-worker/indexhtml?lastModified=${lastModified}&spaVersion=${spaVersion}`;
  if (!initialHtmlPromise) {
    initialHtmlPromise = fetch(endpoint);
  }
  return initialHtmlPromise;
};
