"use strict";
/**
 * class used to store the analytics parameters
 */
Object.defineProperty(exports, "__esModule", { value: true });
var AnalyticsParameters = /** @class */ (function () {
    function AnalyticsParameters() {
        /**
        * represents the application code value;
        * this value (if exists) will be sent to the server.
        */
        this.appCode = '';
        /**
         * represents the application version value;
         * this value (if exists) will be sent to the server.
         */
        this.appVersion = '';
    }
    return AnalyticsParameters;
}());
exports.AnalyticsParameters = AnalyticsParameters;
