/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {FetchScope, query as queryApi} from '@adobe/exc-app/network';
import {getErrorMessage, getOperationName} from '@exc/shared';
import IMetrics from '@adobe/exc-app/metrics/Metrics';
import Metrics, {Level} from '@adobe/exc-app/metrics';

export const PREF_APPID = 'userPreferences';
export const PREF_GROUPID = 'exc-preferences';

let metrics: IMetrics;

let unauthorizedHandler: () => void | undefined;

export class GraphQLError extends Error {
  private readonly _errors: Record<string, SubError[]>|undefined;
  private readonly _requestId: string;

  constructor(message: string, requestId: string, errors?: Record<string, SubError[]>) {
    super(message);
    this._requestId = requestId;
    this._errors = errors;
    Object.setPrototypeOf(this, GraphQLError.prototype);
  }

  get requestId(): string {
    return this._requestId;
  }

  get errors(): Record<string, SubError[]>|undefined {
    return this._errors;
  }
}

export interface GraphQLOptions {
  ignoreErrorsOnPaths?: string[];
  scope?: FetchScope;
}

interface GraphQLErrorInfo {
  extensions?: {
    response?: {
      status: string;
    };
    code?: string;
  }
  message: string;
  path?: string[];
}

export interface GraphQLResponse<T> {
  data: T;
  reqId: string;
  errors?: GraphQLErrorInfo[];
}

export interface QueryOutput<T> {
  data: T;
  reqID: string;
}

interface SubError {
  error: string;
  path?: string;
}

interface ErrorInfo {
  description: string;
  totalErrors: Record<string, SubError[]>;
}

const getErrorInfo = <T>(json: GraphQLResponse<T>, operationName: string): ErrorInfo => {
  const errorMessages: string[] = [];
  const totalErrors: Record<string, SubError[]> = {};

  (json.errors || []).forEach(err => {
    errorMessages.push(err.message);
    const status = err.extensions?.response?.status || err.extensions?.code || 'Unknown Server Error';
    totalErrors[status] = totalErrors[status] || [];
    totalErrors[status].push({
      error: err.message,
      path: err.path && err.path.join('.')
    });
    metrics.error(`[GraphQL Service Error in ${operationName}] Message: ${err.message}`, err, {requestId: json.reqId});
  });

  return {
    description: errorMessages.join(',') || 'Unknown GQL Error',
    totalErrors
  };
};

const handleRequestError = async (res: Response, reqId: string, operationName: string) => {
  let errorDescription = '';
  let gqlErrors = {};
  try {
    if (res.status === 401 && unauthorizedHandler) {
      unauthorizedHandler();
    }

    const errorJson = await res.json();
    // Form the error message object based off whether it is an io error or not
    if (errorJson?.error_code) {
      errorDescription = `${errorJson.message} (${errorJson.error_code})`;
      gqlErrors = {[res.status]: errorDescription};
    } else {
      ({description: errorDescription, totalErrors: gqlErrors} = getErrorInfo(errorJson, operationName));
    }
  } catch {
    errorDescription = 'Unknown GQL Error.';
  }

  throw new GraphQLError(
    `${res.status} - ${errorDescription}`,
    reqId,
    gqlErrors
  );
};

const getGqlResponse = async <T>(
  res: Response,
  ignoreErrorsOnPaths: string[],
  operationName: string
): Promise<QueryOutput<T>> => {
  const reqId = (res?.headers && res.headers.get('x-request-id')) || 'n/a';

  if (res && !res.ok) {
    await handleRequestError(res, reqId, operationName);
  }

  const json = await res.json() || {};

  if (json.errors) {
    const {description, totalErrors} = getErrorInfo(json, operationName);
    const failingPaths: string[] = [];
    for (const path in json.data) {
      // If the path is required, log to EIM and throw an error
      if (json.data[path] === null && !ignoreErrorsOnPaths.includes(path)) {
        metrics.error(`Non-2xx response from backing services in gql: ${description}`, {
          ignoreErrorsOnPaths,
          operationName,
          path,
          reqId,
          totalErrors
        });
        throw new GraphQLError(
          `Non-2xx response from backing services in gql: ${description}`,
          reqId,
          totalErrors
        );
      }
      // Any number of the ignored paths could be failing, so we should keep track of
      // which paths are failing so we can log the data to EIM
      json.data[path] === null && failingPaths.push(path);
    }
    // If there are errors, but we have not thrown an error for a required path,
    // log a warning to EIM
    metrics.warn(`Non-2xx response from ignored path:  ${description}`, {
      ignoreErrorsOnPaths,
      operationName,
      paths: failingPaths,
      reqId,
      totalErrors
    });
  }

  json.reqID = reqId;
  return json;
};

const request = <T, V extends Record<string, any> | undefined>(
  query: string,
  variables: V,
  scope: FetchScope,
  ignoreErrorsOnPaths: string[],
  operationName: string
): Promise<QueryOutput<T>> => queryApi({data: {query, variables}, maxRetries: 5, scope, totalFetchTime: 10000})
    .then(res => getGqlResponse(res, ignoreErrorsOnPaths, operationName));

let authPromise: Promise<void> | undefined;

export const setUnauthorizedHandler = (handler: () => void) => unauthorizedHandler = handler;

export const setAuthPromise = (auth: Promise<void>) => authPromise = auth;

export const gqlQuery = async <T, V extends Record<string, any> | undefined> (
  query: string,
  variables: V,
  options?: GraphQLOptions
): Promise<T> => {
  if (!metrics) {
    metrics = Metrics.create('exc.core.GraphQlService');
  }

  // Wait for auth to be ready before making GraphQL calls.
  authPromise && await authPromise;

  const {ignoreErrorsOnPaths = [], scope = FetchScope.ORG} = options || {};
  const queryName = getOperationName(query) || 'GraphQl';
  const gqlTimer = metrics.start(queryName);
  try {
    const response = await request<T, V>(query, variables, scope, ignoreErrorsOnPaths, queryName);
    const {data} = response;
    if (data) {
      gqlTimer.time('data.initialized', `Initialized Shell Data from GQL required for ${queryName}.`, {requestId: response.reqID});
      metrics.info(`Initialized Shell Data required for ${queryName} using GQL.`, {requestId: response.reqID});
    } else {
      metrics.error(`GQL returned invalid/null response for ${queryName} Data`);
    }
    return data;
  } catch (error) {
    gqlTimer.time(
      'failure',
      `Failed to initialize Shell App Data for ${queryName} using GQL. Error: ${getErrorMessage(error)}`,
      {level: Level.ERROR}
    );
    throw error;
  }
};
