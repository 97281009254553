"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FragmentException = /** @class */ (function () {
    function FragmentException(type, message) {
        this.message = '';
        this.type = '';
        this.type = type;
        this.message = message;
    }
    return FragmentException;
}());
exports.FragmentException = FragmentException;
