/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AdobeIMS} from '@identity/imslib/adobe-ims/AdobeIMS';
import type {APIMode} from '@adobe/exc-app/RuntimeConfiguration';
import type {AuthResponse} from '@exc/graphql/src/models/auth';
import {checkCDN, setPingEndpoint} from '../cdnConnect';
import {cleanTokenFromHash, getCurrentTenantAndPath, getQueryValue} from '@exc/url/query';
import {Config, getConfiguration} from '../utils/config';
import type Configuration from '@adobe/exc-app/metrics/Configuration';
import {getCachedConsent, onConsent} from '../utils/consent';
import {getErrorMessage, isAWSOrgRegion, markEventsPerformance, NOOP} from '@exc/shared';
import {getIMSObjects, IMSObjects} from '../utils/IMSLoader';
import type {ImsProfile} from '@adobe/exc-app/ims/ImsProfile';
import {initializeShellDataForLogin, initializeShellDataForReload} from '@exc/graphql/src/queries/auth';
import {Internal} from '@adobe/exc-app/internal';
import type {LocalSolutions, UnifiedShellConfig, UnifiedShellLocalConfig} from '../models/UnifiedShellConfig';
import Metrics, {Level} from '@adobe/exc-app/metrics';
import {Sandbox} from '@adobe/exc-app/user';
import ServiceWorkerRegisterService from './ServiceWorker/ServiceWorkerRegisterService';
import {setAuthPromise} from '@exc/graphql';
import {storage} from '@exc/storage';

export const UNIFIED_SHELL_APIKEY = 'exc_app';
export const UNIFIED_SHELL_APPID = 'shell';
export const UNIFIED_SHELL_CLIENTID = 'exc_app';

const AUTOMATION_CLIENT_HOLDER = 'exc-automation-client';

export enum AuthState {
  RELOAD = 'RELOAD',
  LOGIN = 'LOGIN',
  NO_AUTH = 'NO_AUTH'
}

// Should extend this interface to the entire object managed by UnifiedShellSessionService.js
interface PartialUnifiedShellSession {
  activeOrg?: string;
  session?: string;
}

interface ErrorResponse {
  error: Error
}

interface CheckTokenResponse extends ImsProfile {
  access_token: string;
}

interface TokenAndProfile {
  access_token: string;
  profile?: ImsProfile;
}

interface AuthInformationSuccess extends IMSObjects {
  activeOrg?: string;
  authState: AuthState;
  fromIMS: boolean;
}

interface AuthInformationFail {
  activeOrg?: string;
  authState: AuthState.NO_AUTH;
  error: string;
  fromIMS: boolean;
}

export type AuthInformation = AuthInformationSuccess | AuthInformationFail;

export const SHELL_MARKED_EVENTS = [
  'AppAssembly.done',
  'AppAssembly.start',
  'Auth.ClusterToken',
  'Auth.tokenPrefetch',
  'Auth.SSO',
  'CustomToken.done',
  'CustomToken.start',
  'Discovery.failure',
  'Discovery.start',
  'Discovery.success',
  'ErrorMessage',
  'IframeTimer.start',
  'PostAuth.loaded',
  'Reload.Reason',
  'Sandbox.connected',
  'Sandbox.onMessage.ping',
  'Sandbox.reconnected',
  'Sandbox.secondPing',
  'ServiceWorker.active',
  'ServiceWorker.appsVersionUpdated',
  'ServiceWorker.installing',
  'ServiceWorker.liveHtml',
  'ServiceWorker.noHtmlUpdates',
  'ServiceWorker.waiting',
  'shellInitDataQuery.data.initialized',
  'shellInitDataQuery.failure',
  'shellInitDataQuery.start',
  'Shell.done',
  'Shell.isLoginFlow.done',
  'Shell.notPermissioned',
  'Shell.start',
  'SolutionSwitcher.Load',
  'Start',
  'SwitchAccount.start',
  'SwitchAccount.switchDone',
  'SwitchAccount.authProcessDone',
  'exc.metrics.pageState.load.done'
];

export interface BootstrapService {
  /**
   * The active CDN endpoint currently used to fetch static resources.
   */
  activeCdn: string;
  /**
   * Should API calls go directly to IO or accelerated via AFD/AWS
   */
  apiMode: APIMode;
  /**
   * Auth information, available if IMS initialized, otherwise use getAuthInformation
   * i.e.bootstrap.authInformation || await bootstrap.getAuthInformation();
   */
  authInformation?: AuthInformation;
  /**
   * IMS Client ID used by Unified Shell
   */
  clientId: string;
  /**
   * Environment config object
   */
  config: Config;
  /**
   * Configures the Runtime network module
   * @param imsOrg - Selected IMS org
   * @param sandbox - Selected PALM Sandbox
   */
  configureNetwork: (imsOrg?: string, sandbox?: Sandbox, orgRegion?: string) => void,
  /**
   * Returns Authentication information (async)
   */
  getAuthInformation: () => Promise<AuthInformation>;
  /**
   * Returns Account Cluster, Settings and Profile Data from GraphQL (async)
   */
  getAccountCluster: () => Promise<AuthResponse|undefined>;
  /**
   * Returns the IMS session ID.
   */
  getSid: () => string;
  /**
   * Returns true if Unified Shell is running inside Automation tests.
   */
  inAutomation: () => boolean;
  /**
   * Returns true if Unified Shell is running in demo mode.
   */
  isDemoMode: () => boolean;
  /**
   * Returns true if Unified Shell is running in dev mode.
   */
  isDevMode: () => boolean;
  /**
   * Loads the application
   */
  load: () => void;
  /**
   * Solution configurations stored locally for dev mode.
   */
  localSolutions: LocalSolutions;
  /**
   * EIM Environment
   */
  metricsEnv: string;
}

const getLocalConfig = (): UnifiedShellLocalConfig => {
  try {
    const localConfigs = window.localStorage.getItem('unifiedShellConfig');
    return localConfigs ? JSON.parse(localConfigs) : {};
  } catch {
    return {};
  }
};

export interface BootstrapData {
  filter?: string;
  shellDataPromise?: Promise<AuthResponse | ErrorResponse>;
  tokenPromise?: Promise<CheckTokenResponse | ErrorResponse>;
}

export type ShellWindow = Window & typeof globalThis & {
  config: UnifiedShellConfig;
  'exc-bootstrap'?: BootstrapData;
  _srp?: {
    resourceURL?: string;
  }
  forceIframeUseSameOrigin?: boolean;
};

const win = (window as ShellWindow);

const resolveGqlPromise = (clusterData: AuthResponse | ErrorResponse) =>
  (!clusterData || 'error' in clusterData) ? initializeShellDataForLogin({}) : clusterData;

class BootstrapServiceImpl implements BootstrapService {
  private _authInfoPromise?: Promise<AuthInformation>;
  private _authInformation?: AuthInformation;
  private _accountClusterPromise?: Promise<AuthResponse|undefined>;
  private _clientId: string = UNIFIED_SHELL_CLIENTID;
  private _ims?: AdobeIMS;
  private _loaded = false;
  private _authConfigResolve = NOOP;
  private readonly _bootstrapData: BootstrapData;
  private readonly _activeCdn: string;
  private readonly _config: Config;
  private readonly _demoMode: boolean;
  private readonly _devMode: boolean;
  private readonly _localConfigs: UnifiedShellLocalConfig;
  private readonly _metricsEnv: string;
  private readonly _serviceWorker = new ServiceWorkerRegisterService();
  private readonly _shellConfig: UnifiedShellConfig;
  private readonly metrics = Metrics.create('exc.core.services.BootstrapService');

  constructor() {
    setAuthPromise(new Promise(resolve => this._authConfigResolve = resolve));
    this._bootstrapData = win['exc-bootstrap'] || {};
    delete win['exc-bootstrap'];

    if (window._srp && window.config) {
      // Since config.cdn always points at front door, use _srp.cdn to point at the actual CDN.
      // This code can be removed after content server is updated to default Shell to AWS.
      (window.config as UnifiedShellConfig).cdn = window._srp.cdn;
      (window.config as UnifiedShellConfig).cdnFallback = window._srp.fallbackLocations;
    }

    this._shellConfig = {scriptsPath: '/assets', ...(win.config || {cdn: '', env: 'dev'})};
    const environment = this._shellConfig.env = this._shellConfig.env.toLowerCase();
    const imsEnv = getQueryValue('ims');
    if (['afd', 'io'].includes(getQueryValue('apimode'))) {
      this._shellConfig.apiMode = getQueryValue('apimode') as APIMode;
    }
    let iframeUseSameOrigin = false;
    const client = this.getAutomationClient();

    // Allow EXC client override on prod, and other clients override on non-prod.
    if ((environment !== 'prod' && imsEnv !== 'prod') || client.startsWith(UNIFIED_SHELL_CLIENTID)) {
      if (win.forceIframeUseSameOrigin) {
        iframeUseSameOrigin = true;
      }

      if (this.inAutomation()) {
        iframeUseSameOrigin = this.sameOriginUnderAutomation();
        client && (this._clientId = client);
      }
    }

    this._config = getConfiguration(
      {...this._shellConfig, environment, iframeUseSameOrigin},
      this._clientId,
      imsEnv
    );

    this._localConfigs = getLocalConfig();
    this._demoMode = getQueryValue('demo') === 'true';
    this._devMode = this._localConfigs.devmodeEnabled || getQueryValue('devmode') === 'true';
    this._metricsEnv = this._devMode ? 'dev' : environment;
    this.initMetrics();

    this.config.cdn || (this.config.cdn = location.origin);
    let {cdn} = this.config;
    if (win._srp?.resourceURL && win._srp.resourceURL !== cdn) {
      cdn = win._srp.resourceURL;
      this.metrics.event('Shell.CDNFallback', `Using ${cdn} for static assets.`, {level: Level.WARN});
    }
    this._activeCdn = cdn;

    setPingEndpoint(this._config);
  }

  private initMetrics(): void {
    const {env, spaVersion = 'local.development'} = this._shellConfig;
    const mode = getQueryValue('metricsmode') || (this.config.environment === 'prod' && 'quiet');
    const metricsConfig = {
      analytics: {
        script: env === 'prod' ?
          '//exc-unifiedcontent.experience.adobe.net/static/launch/a7d65461e54e/bca0d85cdac1/launch-73fb0b595c8c.min.js' :
          '//assets.adobedtm.com/a7d65461e54e/bca0d85cdac1/launch-e6337088c5a2-staging.min.js',
        solution: 'exc' // initial value
      },
      application: {
        id: 'unified-shell',
        solution: {
          id: 'n/a'
        },
        version: spaVersion
      },
      environment: this._metricsEnv
    } as Configuration;

    mode && (metricsConfig.mode = mode);

    // Update opt out based on local storage cached version of user consent.
    const consentCache = getCachedConsent();
    onConsent(consentCache, this.metrics, false);

    Internal.configureMetrics(metricsConfig);

    // Adds a filter to Metrics which will create performance marks
    // for the events listed in SHELL_MARKED_EVENTS
    markEventsPerformance(
      window,
      SHELL_MARKED_EVENTS,
      event => `us_${event.toLowerCase().replace(/[ ().\-&]+/g, '_')}`
    );

    const solutionCount = Object.keys(this._shellConfig.solutions || {}).length;
    this.metrics.event('Start', {...this._shellConfig, solutionCount});
    if (!solutionCount || !this._shellConfig.tis) {
      this.metrics.error('Invalid Config', win.config);
    }
  }

  public load(): void {
    if (!this._loaded) {
      this._loaded = true;
      this._serviceWorker.load();
      // The account cluster flow also loads IMS lib, which acquires an access token if needed.
      this.getAccountCluster();
    }
  }

  public getSid = () => (this._ims?.getAccessToken() || {}).sid || '';

  private async checkAuthState(hasToken: boolean, fromIMS: boolean, localSessionId?: string): Promise<AuthState> {
    let authState = AuthState.NO_AUTH;
    if (hasToken) {
      // User is authenticated.
      authState = AuthState.RELOAD;
      const authSessionId = this.getSid();
      if (fromIMS && localSessionId === authSessionId) {
        this.metrics.event('Auth.alreadyLoggedIn');
      }
      // If the user is authenticated and sessionId is empty or out of date,
      // treat it as login to trigger SSO handler in Core.
      if (localSessionId !== authSessionId) {
        authState = AuthState.LOGIN;
        localSessionId && storage.local.set('unifiedShellSession', {});
        fromIMS || this.metrics.event(
          'Auth.SSO', {authSessionId: !!authSessionId, referrer: document.referrer, storageSessionId: !!localSessionId}
        );
      }
    }
    return authState;
  }

  private async getToken(promise: Promise<CheckTokenResponse | ErrorResponse>, filter?: string): Promise<TokenAndProfile> {
    try {
      const tokenProfile = await promise;
      if ('error' in tokenProfile) {
        const error = getErrorMessage(tokenProfile.error);
        this.metrics.error('Failed to prefetch token', {error});
        return {access_token: ''};
      }
      if (tokenProfile.access_token && tokenProfile.userId) {
        const {access_token, ...profile} = tokenProfile;
        this.metrics.event('Auth.tokenPrefetch', {filter});
        return {
          access_token,
          profile
        };
      }
      this.metrics.error('Failed to prefetch token - Invalid response', {error: tokenProfile});
    } catch (err) {
      const error = getErrorMessage(err);
      this.metrics.error('Failed to prefetch token', {error});
    }
    return {access_token: ''};
  }

  private async loadIMS(): Promise<AuthInformation> {
    const {filter, tokenPromise} = this._bootstrapData;
    const fromIMS = window.location.hash.includes('from_ims');
    try {
      let prefetchedProfile: ImsProfile | undefined;
      let token = ['dev', 'qa'].includes(this._config.environment) ? getQueryValue('customtoken') || '' : '';
      token && this.metrics.info('customtoken exists'); // Debugging to determine if needed
      if (!token && tokenPromise) {
        const {access_token, profile} = await this.getToken(tokenPromise, filter);
        prefetchedProfile = profile;
        token = access_token;
      }
      const imsObjects = await getIMSObjects({
        clientId: this._clientId,
        env: this._config.serviceEnvironment,
        profile: prefetchedProfile,
        token
      });
      const {adobeIMS} = imsObjects;
      this._ims = adobeIMS;
      // Check for custom query.
      const hasToken = adobeIMS.isSignedInUser();
      const {activeOrg = undefined, session = undefined} = hasToken ?
        (await storage.local.get<PartialUnifiedShellSession>('unifiedShellSession') || {}) :
        {};

      const authState = await this.checkAuthState(hasToken, fromIMS, session);

      // If this is a whole new Unified Shell version, let's download it while the user is in SUSI.
      if (authState === AuthState.NO_AUTH) {
        this.metrics.event('Login.imsSSO.ready', 'Invalid session: imsSSO login required');
        this._serviceWorker.prepareForLogin();
      } else {
        checkCDN();
        this.configureNetwork();
      }
      // Clean token from URL if it exists
      const cleanedHash = cleanTokenFromHash(location.hash);
      if (cleanedHash) {
        this.metrics.warn('Access token found in window hash');
        location.hash = cleanedHash;
      }

      this._authInformation = {
        ...imsObjects,
        activeOrg,
        authState,
        fromIMS
      };
    } catch (err: any) {
      const error = err?.message || 'Unknown Error';
      this.metrics.error('Failed to initialize IMS lib', {error, stack: err?.stack});
      this._serviceWorker.prepareForLogin();
      this._authInformation = {
        authState: AuthState.NO_AUTH,
        error,
        fromIMS
      };
    }
    return this._authInformation;
  }

  public getAuthInformation(): Promise<AuthInformation> {
    if (!this._authInfoPromise) {
      this._authInfoPromise = this.loadIMS();
    }
    return this._authInfoPromise;
  }

  public get authInformation(): AuthInformation | undefined {
    return this._authInformation;
  }

  public get config(): Config {
    return this._config;
  }

  public configureNetwork(imsOrg?: string, sandbox?: Sandbox, orgRegion?: string): void {
    const {token: imsToken} = this._ims?.getAccessToken() || {};
    if (!imsToken) {
      this.metrics.warn('Can not configure network module without a token.');
      return;
    }
    this._authConfigResolve();
    Internal.configureNetwork({
      apiGatewayUrl: this._config.endpoints.apiGateway,
      apiKey: UNIFIED_SHELL_APIKEY,
      apiMode: this._config.apiMode,
      appId: UNIFIED_SHELL_APPID,
      imsOrg,
      imsToken,
      ioGatewayUrl: this.config.endpoints.ioGateway,
      ioRegionSpecificMap: this.config.endpoints.regionGatewayIoMap,
      isAWSOrg: orgRegion ? isAWSOrgRegion(orgRegion) : false,
      orgRegion,
      sandbox,
      xqlGatewayUrl: this.config.endpoints.xql
    });
  }

  public async getAccountCluster(): Promise<AuthResponse|undefined> {
    const {activeOrg, authState} = this._authInformation || await this.getAuthInformation();
    const {shellDataPromise} = this._bootstrapData;
    const {tenant: selectedOrg} = getCurrentTenantAndPath();

    if (!this._accountClusterPromise) {
      switch (authState) {
        case AuthState.LOGIN:
          this._accountClusterPromise = shellDataPromise ? shellDataPromise.then(resolveGqlPromise) : initializeShellDataForLogin({selectedOrg});
          break;
        case AuthState.RELOAD:
          this._accountClusterPromise = initializeShellDataForReload({selectedOrg: selectedOrg || activeOrg});
          break;
        default:
          // No response if user is not authenticated, return undefined.
          this._accountClusterPromise = Promise.resolve(undefined);
      }
    }
    return this._accountClusterPromise;
  }

  public get localSolutions(): LocalSolutions {
    if (this._devMode && this._localConfigs.solutions) {
      return this._localConfigs.solutions;
    }
  }

  public get apiMode(): APIMode {
    return this._config.apiMode;
  }

  public get metricsEnv(): string {
    return this._metricsEnv;
  }

  public get activeCdn(): string {
    return this._activeCdn;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public isDemoMode = () => this._demoMode;

  public isDevMode = () => this._devMode;

  public inAutomation = () =>
    (window !== window.parent || window.navigator.webdriver) &&
    ('Cypress' in window || 'UnifiedShellTestClient' in window);

  public sameOriginUnderAutomation(): boolean {
    const w = window as any;
    const config = w.Cypress?.config ? w.Cypress.config() : {};
    if ('iframeUseSameOrigin' in config) {
      return !!config.iframeUseSameOrigin;
    }
    return true;
  }

  private getAutomationClient(): string {
    const w = window as any;
    const config = w.Cypress?.config ? w.Cypress.config() : {};
    // Developers can add the `UnifiedShellTestClient` property on the window
    // to tell the Shell which client ID to use.
    if (w.UnifiedShellTestClient && !Object.keys(config).length) {
      config[AUTOMATION_CLIENT_HOLDER] = w.UnifiedShellTestClient === 'inherit' ? UNIFIED_SHELL_CLIENTID : w.UnifiedShellTestClient;
    }
    return w[AUTOMATION_CLIENT_HOLDER] || config[AUTOMATION_CLIENT_HOLDER] || '';
  }
}

let bootstrap: BootstrapService | undefined;

/**
 * Returns an instance of the Bootstrap service.
 */
export const getBootstrap = (): BootstrapService => {
  if (!bootstrap) {
    bootstrap = new BootstrapServiceImpl();
  }
  return bootstrap;
};
