"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdobeIdThinData_1 = require("../adobe-id/AdobeIdThinData");
var RideException_1 = require("../token/RideException");
var HttpErrorResponse_1 = require("./HttpErrorResponse");
/**
 * class used to create a IHttpErrorResponse object in case the code is != 200
 */
var HttpErrorHandler = /** @class */ (function () {
    function HttpErrorHandler() {
        this.adobeIdThinData = null;
    }
    HttpErrorHandler.prototype.verify = function (exception, url) {
        if (url === void 0) { url = ''; }
        var status = exception.status, data = exception.data;
        if (!status) {
            return new HttpErrorResponse_1.HttpErrorResponse({
                error: 'networkError',
                message: data || ''
            });
        }
        if (status == 401) {
            return new HttpErrorResponse_1.HttpErrorResponse({
                error: 'unauthorized',
            });
        }
        var rideException = this.parseTokenResponseForRideErrors(data, url);
        if (rideException) {
            return rideException;
        }
        if (status == 409) {
            return data;
        }
        if (status == 429) {
            return new HttpErrorResponse_1.HttpErrorResponse({
                error: 'rate_limited',
                retryAfter: data.retryAfter ? parseInt(data.retryAfter) : 10,
            });
        }
        if (status.toString().match(/5\d{2}/g)) {
            return new HttpErrorResponse_1.HttpErrorResponse({
                error: 'server_error'
            });
        }
        return null;
    };
    /**
        * parse the check token response to see if there are any ride errors
        * @param refreshException represents the response from check token call
        * @param url the called url; part of the PBA Expired Idle Session workaround
        * @returns a RideException or null in case that the error code is not one from RideExceptions
        */
    HttpErrorHandler.prototype.parseTokenResponseForRideErrors = function (refreshException, url) {
        if (!refreshException) {
            return null;
        }
        var error = refreshException.error, jump = refreshException.jump;
        if (!error) {
            return null;
        }
        var isRideError = error.indexOf('ride_') === 0;
        if (!isRideError) {
            // the PBA Expired Idle Session workaround: If a check/token call fails
            // because the session has been idle longer that the org policy limit,
            // a `token_expired` error is returned. But it should've been a ride exception
            // so the user is redirected to SUSI. Until the fix is made in the backend
            // this workaround has been implemented
            if (error === 'token_expired' && url.indexOf('check/v6/token') >= 0) {
                return new RideException_1.RideException('ride_pba_idle_session', '', true);
            }
            return null;
        }
        var jumpValue = this.addRedirectUriToJump(error, jump);
        return new RideException_1.RideException(error, jumpValue);
    };
    HttpErrorHandler.prototype.addRedirectUriToJump = function (error, jump) {
        if (!jump || typeof jump !== 'string') {
            return '';
        }
        var jumpValue = jump;
        if (!this.adobeIdThinData) {
            this.adobeIdThinData = new AdobeIdThinData_1.AdobeIdThinData();
        }
        var redirectUri = this.adobeIdThinData.computeRideRedirectUri(error);
        if (!redirectUri || redirectUri.length === 0) {
            return jumpValue;
        }
        try {
            var jumpUrl = new URL(jumpValue);
            jumpUrl.searchParams.append("redirect_uri", redirectUri);
            return jumpUrl.toString();
        }
        catch (e) {
            return jumpValue;
        }
    };
    /**
     * check if the exception status has the code equal 401
     * @param exception represents the exception caught during get profile method
     * @returns true if the status code is 401, otherwise false
     */
    HttpErrorHandler.prototype.isUnauthorizedException = function (exception) {
        var _a = exception.status, status = _a === void 0 ? 0 : _a;
        return status === 401;
    };
    return HttpErrorHandler;
}());
exports.default = new HttpErrorHandler();
