"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ImsConstants_1 = require("../constants/ImsConstants");
var PopupSettings_1 = require("../adobe-ims/sign-in/PopupSettings");
var StandaloneToken_1 = require("../token/StandaloneToken");
var IReauth_1 = require("../adobe-ims/facade/IReauth");
var IGrantTypes_1 = require("../adobe-ims/facade/IGrantTypes");
var AdobeIdThinData_1 = require("./AdobeIdThinData");
/**
 *  Class used to store the adobe data values.
 *
 *  Ims library will use these values for all operations
 */
var AdobeIdData = /** @class */ (function (_super) {
    __extends(AdobeIdData, _super);
    /**
     * @constructor Create the adobeIdData object with all necessary properties from adobeData input paramater
     *
     * It uses the input adobeData parameter or the object stored in window.adobeid
     */
    function AdobeIdData(adobeData) {
        if (adobeData === void 0) { adobeData = null; }
        var _this = _super.call(this, adobeData) || this;
        /** @function {adobeid.onAccessTokenHasExpired} onAccessTokenHasExpired
         *  Function to be executed if the 'access_token' is invalid.
         */
        _this.onAccessTokenHasExpired = null;
        /**  @function {adobeid.onAccessToken} - Function to be executed once imslib.js has acquired
         * an `access_token`.
         */
        _this.onAccessToken = null;
        /**
         * @function {adobeid.onReauthAccessToken} Function used to trigger the reauth access token
         */
        _this.onReauthAccessToken = null;
        /**
         * @function {adobeid.onError}
         * Function used to notify external libraries for ims errors
         */
        _this.onError = null;
        /**
         * Handlers object is used to invoke the adobe id data events.
         *
         * When a token, profile is aquired, or token is expired and library is fully intialized, an event is triggered
         */
        _this.handlers = {
            triggerOnAccessToken: function (data) {
                _this.onAccessToken && _this.onAccessToken(data);
            },
            triggerOnReauthAccessToken: function (data) {
                _this.onReauthAccessToken && _this.onReauthAccessToken(data);
            },
            triggerOnAccessTokenHasExpired: function () {
                _this.onAccessTokenHasExpired && _this.onAccessTokenHasExpired();
            },
            triggerOnReady: function (context) {
                if (context === void 0) { context = null; }
                _this.onReady && _this.onReady(context);
            },
            triggerOnError: function (errorType, error) {
                _this.onError && _this.onError(errorType, error);
            }
        };
        var adobeid = adobeData ? adobeData : window[ImsConstants_1.AdobeIdKey];
        if (!adobeid || !adobeid.client_id) {
            throw new Error("Please provide required adobeId, client_id information");
        }
        var standalone = adobeid.standalone, autoValidateToken = adobeid.autoValidateToken, _a = adobeid.modalSettings, modalSettings = _a === void 0 ? {} : _a, _b = adobeid.modalMode, modalMode = _b === void 0 ? false : _b, onAccessToken = adobeid.onAccessToken, onReauthAccessToken = adobeid.onReauthAccessToken, onAccessTokenHasExpired = adobeid.onAccessTokenHasExpired, onReady = adobeid.onReady, onError = adobeid.onError, overrideErrorHandler = adobeid.overrideErrorHandler, onModalModeSignInComplete = adobeid.onModalModeSignInComplete;
        if (standalone && standalone.token) {
            _this.standalone = new StandaloneToken_1.StandaloneToken(standalone);
        }
        _this.modalSettings = new PopupSettings_1.PopupSettings(modalSettings);
        _this.modalMode = modalMode;
        _this.autoValidateToken = !!autoValidateToken;
        _this.onAccessToken = onAccessToken ? onAccessToken : null;
        _this.onReauthAccessToken = onReauthAccessToken ? onReauthAccessToken : null;
        _this.onAccessTokenHasExpired = onAccessTokenHasExpired
            ? onAccessTokenHasExpired
            : null;
        _this.onReady = onReady ? onReady : null;
        _this.onError = onError ? onError : null;
        _this.overrideErrorHandler = overrideErrorHandler;
        _this.onModalModeSignInComplete = onModalModeSignInComplete;
        return _this;
    }
    /**
     * Function used by IMSLib to use only the neccesarry properties from AdobeIdData for social provider
     * @param providerName provider name used for sign in
     * @param requestedParameters {Object} the external parameters used for signin and reauth methods
     * @param contextToBePassedOnRedirect {any | undefined} represents the context which is passed during redirect
     */
    AdobeIdData.prototype.createSocialProviderRedirectRequest = function (providerName, requestedParameters, contextToBePassedOnRedirect, nonce, grantType) {
        if (grantType === void 0) { grantType = IGrantTypes_1.IGrantTypes.token; }
        var params = {
            idp_flow: "social.deep_link.web",
            provider_id: providerName,
        };
        var signInParams = __assign(__assign({}, requestedParameters), params);
        return this.createRedirectRequest(signInParams, contextToBePassedOnRedirect, nonce, grantType);
    };
    /**
     * Function used by ims to use only the neccesarry properties from AdobeIdData on sign in and reauth methods
     * @param requestedParameters {Object} the external parameters used for signin and reauth methods
     * @param contextToBePassedOnRedirect {any | undefined} represents the context which is passed during redirect
     * @param nonce {string} - string representing the nonce value used for CSRF
     * @param reauth {string}; represents the re authenticate value. available values are: check and force. default value is "check"
     */
    AdobeIdData.prototype.createReAuthenticateRedirectRequest = function (requestedParameters, contextToBePassedOnRedirect, nonce, reauth, grantType) {
        if (reauth === void 0) { reauth = IReauth_1.IReauth.check; }
        if (grantType === void 0) { grantType = IGrantTypes_1.IGrantTypes.token; }
        var params = {
            reauth: reauth,
        };
        var reauthParams = __assign(__assign({}, requestedParameters), params);
        return this.createRedirectRequest(reauthParams, contextToBePassedOnRedirect, nonce, grantType);
    };
    /**
     * Function used by ims to use only the neccesarry properties from AdobeIdData on sign in and reauth methods
     * @param requestedParameters {Object} the external parameters used for signin and reauth methods
     * @param contextToBePassedOnRedirect {any | undefined} represents the context which is passed during redirect
     */
    AdobeIdData.prototype.createSignUpRedirectRequest = function (requestedParameters, contextToBePassedOnRedirect, nonce, grantType) {
        if (grantType === void 0) { grantType = IGrantTypes_1.IGrantTypes.token; }
        var signupParams = __assign(__assign({}, requestedParameters), { idp_flow: "create_account" });
        return this.createRedirectRequest(signupParams, contextToBePassedOnRedirect, nonce, grantType);
    };
    return AdobeIdData;
}(AdobeIdThinData_1.AdobeIdThinData));
exports.AdobeIdData = AdobeIdData;
