"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TokenProfileResponse = /** @class */ (function () {
    function TokenProfileResponse(tokenFields, profile) {
        this.profile = null;
        this.tokenFields = tokenFields;
        this.profile = profile;
    }
    return TokenProfileResponse;
}());
exports.TokenProfileResponse = TokenProfileResponse;
