/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {storage, StorageInstance} from './storage';

// If a user does not open any discovery based solution in 28 days, cache will be removed.
export const DEFAULT_CACHE_TTL = 4 * 7 * 86400; // 4 weeks (seconds).

export interface CacheEntry {
  /**
   * Expiry (In timestamp ms)
   */
  expires: number;
}

export class StorageCache<T extends CacheEntry> {
  private readonly storageKey: string;
  private readonly cacheExpiry: number;
  private readonly storage: StorageInstance;

  constructor(storageInstance: StorageInstance, storageKey: string, cacheExpiry = DEFAULT_CACHE_TTL) {
    this.storageKey = storageKey;
    this.storage = storageInstance;
    this.cacheExpiry = cacheExpiry;
  }

  /**
   * Reads the entire cache from storage.
   * @returns {{[key: string]: T}} Cache content
   * @private
   */
  private async read(): Promise<{[key: string]: T}> {
    return await this.storage.get(this.storageKey) || {};
  }

  /**
   * Writes the entire cache to storage, removing expired items along the way.
   * @param {[key: string]: T} cache - Cache content
   * @private
   */
  private async write(cache: {[key: string]: T}): Promise<void> {
    const now = Date.now();
    // Remove any expired entries
    for (const key in cache) {
      if (cache[key].expires < now) {
        delete cache[key];
      }
    }
    await this.storage.set(this.storageKey, cache, this.cacheExpiry * 1000);
  }

  /**
   * Returns a cached entry if exists and valid.
   * @param {string} key - Entry key
   *
   * @returns {CacheEntry} Cached entry
   */
  public async get(key: string): Promise<T|undefined> {
    const cache = await this.read();
    const entry = cache[key];
    if (entry && entry.expires > Date.now()) {
      return entry;
    }
  }

  /**
   * Stores an entry in the cache.
   * @param {string} key - Entry key
   * @param {CacheEntry} entry to be cached.
   */
  public async set(key: string, entry: T): Promise<void> {
    const cache = await this.read();
    cache[key] = entry;
    await this.write(cache);
  }

  /**
   * Removes an entry from the cache if exists.
   * @param {string} key - Entry key
   */
  public async remove(key: string): Promise<void> {
    const cache = await this.read();
    if (cache[key]) {
      delete cache[key];
      await this.write(cache);
    }
  }
}

function getLocalCache<T extends CacheEntry>(storageKey: string, cacheExpiry?: number): StorageCache<T> {
  return new StorageCache<T>(storage.local, storageKey, cacheExpiry);
}

function getSessionCache<T extends CacheEntry>(storageKey: string, cacheExpiry?: number): StorageCache<T> {
  return new StorageCache<T>(storage.session, storageKey, cacheExpiry);
}

export {
  getLocalCache,
  getSessionCache
};
