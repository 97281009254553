"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SCOPE_SEPARATOR = ',';
function sortScopes(scopes) {
    return scopes.split(SCOPE_SEPARATOR).sort().join(SCOPE_SEPARATOR);
}
exports.sortScopes = sortScopes;
function validateScopeInclusion(requestedScope, tokenScope) {
    var tokenScopes = (tokenScope === null || tokenScope === void 0 ? void 0 : tokenScope.split(SCOPE_SEPARATOR)) || [];
    var requestedScopes = (requestedScope === null || requestedScope === void 0 ? void 0 : requestedScope.split(SCOPE_SEPARATOR)) || [];
    return requestedScopes.every(function (scope) { return tokenScopes.includes(scope); });
}
exports.validateScopeInclusion = validateScopeInclusion;
