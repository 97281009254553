"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var CheckTokenEndpoint = /** @class */ (function () {
    function CheckTokenEndpoint(proxied, url, fallbackUrl) {
        if (proxied === void 0) { proxied = false; }
        if (url === void 0) { url = ''; }
        if (fallbackUrl === void 0) { fallbackUrl = ''; }
        this.proxied = proxied;
        this.url = url;
        this.fallbackUrl = fallbackUrl;
    }
    CheckTokenEndpoint.computeEndpoint = function (useProxy, hostname, isStage, servicesUrl) {
        var e_1, _a;
        if (useProxy) {
            var domainMap = isStage ? CheckTokenEndpoint.THIRD_PARTY_DOMAINS_STAGE
                : CheckTokenEndpoint.THIRD_PARTY_DOMAINS_PROD;
            try {
                for (var _b = __values(Object.keys(domainMap)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var domain = _c.value;
                    if (hostname === domain || hostname.endsWith('.' + domain)) {
                        return new CheckTokenEndpoint(true, domainMap[domain], servicesUrl);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return new CheckTokenEndpoint(false, servicesUrl);
    };
    CheckTokenEndpoint.prototype.shouldFallbackToAdobe = function (e) {
        if (!this.proxied) {
            return false;
        }
        return e.error === 'feature_disabled' && e.error_description === 'cdsc';
    };
    // The /check/v*/token API is proxied on some third party domains and
    // imslib should use those endpoints when running on those domains.
    CheckTokenEndpoint.THIRD_PARTY_DOMAINS_PROD = {
        'behance.net': 'https://sso.behance.net'
    };
    CheckTokenEndpoint.THIRD_PARTY_DOMAINS_STAGE = {
        's2stagehance.com': 'https://sso.s2stagehance.com'
    };
    return CheckTokenEndpoint;
}());
exports.CheckTokenEndpoint = CheckTokenEndpoint;
