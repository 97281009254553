"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ApiResponse_1 = require("./ApiResponse");
exports.default = new /** @class */ (function () {
    function Xhr() {
    }
    Xhr.prototype.http = function (options) {
        return new Promise(function (resolve, reject) {
            var xmlHttpObject = window.XMLHttpRequest;
            var xhr = new xmlHttpObject();
            xhr.withCredentials = true;
            xhr.open(options.method, options.url, true);
            var addHeaders = function (headers) {
                if (!headers) {
                    return;
                }
                Object.keys(headers).forEach(function (key) {
                    xhr.setRequestHeader(key, headers[key]);
                });
            };
            xhr.onload = function onload() {
                if (this.status >= 200 && this.status < 300) {
                    return resolve(new ApiResponse_1.ApiResponse(this.status, this.response));
                }
                return reject(new ApiResponse_1.ApiResponse(this.status, this.response));
            };
            xhr.onerror = function onerror() {
                var error = new ApiResponse_1.ApiResponse(this.status, this.response);
                return reject(error);
            };
            addHeaders(options.headers);
            xhr.send(options.data);
        });
    };
    Xhr.prototype.post = function (url, data, headers) {
        if (headers === void 0) { headers = {}; }
        return this.http({
            headers: headers,
            method: 'POST',
            url: url,
            data: data,
        });
    };
    Xhr.prototype.get = function (url, headers) {
        if (headers === void 0) { headers = {}; }
        return this.http({
            headers: headers,
            method: 'GET',
            url: url,
        });
    };
    return Xhr;
}());
