"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enumeration values for available environments
 */
var IEnvironment;
(function (IEnvironment) {
    /**
     * Stage environment
     */
    IEnvironment["STAGE"] = "stg1";
    /**
     * Prod environment
     */
    IEnvironment["PROD"] = "prod";
})(IEnvironment = exports.IEnvironment || (exports.IEnvironment = {}));
