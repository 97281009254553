"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
var ALLOWED_ORIGINS = ['https://auth.services.adobe.com', 'https://auth-stg1.services.adobe.com', 'https://localhost.corp.adobe.com:9000'];
/**
 * class used to open a new authorization popup
 */
var PopupOpener = /** @class */ (function () {
    function PopupOpener() {
        var _this = this;
        /**
         * refernce of the window object
         */
        this.windowObjectReference = null;
        /**
         * represents the previous url used to open the popup window
         */
        this.previousUrl = '';
        /**
         * open the popup window used for authorization
         * @param url {String} url of the popup window
         * @param popupSettings {object} contains the popup setting as height and width
         * @param onPopupMessage {Function} function used to transfer the data from popup
         */
        this.openSignInWindow = function (url, popupHaspProp, popupSettings, onPopupMessage) {
            _this.onProcessLocation = onPopupMessage;
            _this.allowOrigin = popupSettings.allowOrigin;
            if (_this.timerId) {
                clearInterval(_this.timerId);
            }
            // remove any existing event listeners
            window.removeEventListener('message', _this.receiveMessage);
            // add the listener for receiving a message from the popup
            window.addEventListener('message', _this.receiveMessage);
            // window features
            var strWindowFeatures = "toolbar=no, menubar=no, width=" + popupSettings.width + ", height=" + popupSettings.height + ", top=" + popupSettings.top + ", left=" + popupSettings.left;
            if (!_this.windowObjectReference || (_this.windowObjectReference && _this.windowObjectReference.closed)) {
                /* if the pointer to the window object in memory does not exist or if such pointer exists but the window was closed */
                _this.windowObjectReference = window.open(url, popupSettings.title, strWindowFeatures);
            }
            else if (_this.previousUrl !== url) {
                /* if the resource to load is different, then we load it in the already opened secondary window and then  we bring such window back on top/in front of its parent window. */
                _this.windowObjectReference = window.open(url, popupSettings.title, strWindowFeatures);
                _this.windowObjectReference && _this.windowObjectReference.focus();
            }
            else {
                /* else the window reference must exist and the window is not closed; therefore, we can bring it back on top of any other window with the focus() method. There would be no need to re-create
                    the window or to reload the referenced resource. */
                _this.windowObjectReference.focus();
            }
            var popupWnd = _this.windowObjectReference || {};
            if (!popupWnd.opener) {
                _this.timerId = setInterval(function () {
                    if (!popupWnd[popupHaspProp]) {
                        return;
                    }
                    clearInterval(_this.timerId);
                    _this.onProcessLocation && _this.onProcessLocation(popupWnd[popupHaspProp]);
                    delete popupWnd[popupHaspProp];
                    _this.windowObjectReference && _this.windowObjectReference.close();
                }, 500);
            }
            // assign the previous URL
            _this.previousUrl = url;
        };
        /**
         * function used to receive the message from the popup window
         * @param event {window event} contains the dom event passed by popup window
         */
        this.receiveMessage = function (event) {
            if (!__spread(ALLOWED_ORIGINS, [_this.allowOrigin]).includes(event.origin)) {
                return;
            }
            try {
                if (!URL_REGEX.test(event.data)) {
                    return;
                }
            }
            catch (err) {
                return;
            }
            _this.onProcessLocation && _this.onProcessLocation(event.data);
        };
    }
    return PopupOpener;
}());
exports.default = new PopupOpener();
