"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * class used to implement the main functions used for query strings
 */
var UrlHelper = /** @class */ (function () {
    function UrlHelper() {
    }
    /**
   * Encodes data as a query string.
   * @param {Object} data - The data.
   * @returns {string} - The encoded string.
    * @example
    * encoded = uriEncodeData({
    *   first: true,
    *   foo: {
    *     bar: 'foobar'
    *   }
    * }) // -> first=true&foo=%7B%22bar%22%3A%22foobar%22%7D
    */
    UrlHelper.prototype.uriEncodeData = function (data) {
        if (typeof data !== 'object') {
            return '';
        }
        var encodings = [];
        var encodedValue = '';
        var keyValue;
        for (var key in data) {
            keyValue = data[key];
            if (keyValue === undefined) {
                continue;
            }
            encodedValue = this.encodeValue(keyValue);
            encodings.push(encodeURIComponent(key) + "=" + encodedValue);
        }
        return encodings.join("&");
    };
    /**
   * @param value : any; represents the value which will be encoded
   * @returns string.
   */
    UrlHelper.prototype.encodeValue = function (value) {
        if (value === null) {
            return 'null';
        }
        if (typeof value === 'object') {
            return encodeURIComponent(JSON.stringify(value));
        }
        return encodeURIComponent(value);
    };
    /**
   * Opens the URL in the current page
   *
   * @param {!string} url
   */
    UrlHelper.prototype.replaceUrl = function (url) {
        if (!url) {
            return;
        }
        window.location.replace(url);
    };
    UrlHelper.prototype.sleep = function (timeout) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, timeout); })];
            });
        });
    };
    UrlHelper.prototype.replaceUrlAndWait = function (url, timeout) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!url) {
                            return [2 /*return*/, Promise.resolve()];
                        }
                        window.location.replace(url);
                        return [4 /*yield*/, this.sleep(timeout)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    /**
   * Opens the URL in the current page
   * @param {!string} url
   */
    UrlHelper.prototype.setHrefUrl = function (url) {
        if (!url) {
            return;
        }
        window.location.href = url;
    };
    /**
  * Change the hash from url to a new value without reloading the page
  * @param hash {String} represents the new hash value
  *
  */
    UrlHelper.prototype.setHash = function (hash) {
        if (hash === void 0) { hash = ''; }
        window.location.hash = hash;
    };
    return UrlHelper;
}());
exports.default = new UrlHelper();
