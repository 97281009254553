"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * In-Memory polyFill for localStorage and sessionStorage.
 *
 * @constructor
 * @description Needed for Mac and iOS Safari (v <= 10) in Private Mode. Note that this object doesn't actually
 * persist data across refresh, it just implements the [Storage]{@link external:Storage} interface.
 * @exports memoryStorage
 */
var MemoryStorage = /** @class */ (function () {
    function MemoryStorage() {
        /**
         * @type {!Object}
         */
        this.data = {};
        /**
         * property added in order to be compatible with Storage class
         */
        this.length = 0;
    }
    /**
     * clear the memory data
     */
    MemoryStorage.prototype.clear = function () {
        this.data = {};
        this.length = 0;
    };
    /**
     * @param key {String} represents the used key to get a value
     * @returns {Object} the value associated with the input key
     */
    MemoryStorage.prototype.getItem = function (key) {
        var value = this.data[key];
        return value ? value : null;
    };
    /**
     *
     * @param key {string} represent the key which will be removed from memory
     * @returns {boolean} true if the key is removed otherwise false
     */
    MemoryStorage.prototype.removeItem = function (key) {
        if (!this.data[key]) {
            return false;
        }
        delete this.data[key];
        --this.length;
        return true;
    };
    /**
     * @key {String} - the used key to store a value into memory
     * @value {any} - value associated with the input key
     */
    MemoryStorage.prototype.setItem = function (key, value) {
        if (!this.data[key]) {
            ++this.length;
        }
        this.data[key] = value;
    };
    MemoryStorage.prototype.key = function (index) {
        throw new Error("Method not implemented. " + index);
    };
    return MemoryStorage;
}());
exports.MemoryStorage = MemoryStorage;
