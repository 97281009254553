"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enumeration values used for defining possible fragment exception types
 *
 */
var IFragmentExceptionType;
(function (IFragmentExceptionType) {
    /**
     * fragment exception type
     */
    IFragmentExceptionType["FRAGMENT"] = "fragment";
    /**
     * csrf exception type
     */
    IFragmentExceptionType["CSRF"] = "csrf";
    IFragmentExceptionType["NOT_AUTHORIZE"] = "not_authorize";
    IFragmentExceptionType["API_NOT_ALLOWED"] = "not_allowed";
})(IFragmentExceptionType = exports.IFragmentExceptionType || (exports.IFragmentExceptionType = {}));
